import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useCallback } from 'react';
import styles from './KemuCheckbox.module.css';

export type { CheckboxChangeEvent };

type Props = {
  checked?: boolean;
  onChange?: (event: CheckboxChangeEvent, elementId?: string | number) => void;
  elementId?: string | number;
  children?: React.ReactNode;
  className?: string;
}

const KemuCheckbox = (props: Props) => {
  const { checked, onChange, elementId, children, className } = props;

  const handleChange = useCallback((evt: CheckboxChangeEvent) => {
    onChange?.(evt, elementId);
  }, [onChange, elementId]);

  return (
    <Checkbox
      checked={checked}
      onChange={handleChange}
      className={className}
    >
      {children}
    </Checkbox>
  );
};

export default KemuCheckbox;
