import React, { memo, useCallback, useEffect, useRef } from 'react';
import { Modal, Space } from 'antd';
import classNames from 'classnames';
import StyledButton, { ButtonColors } from '../form-control/styledButton/styledButton';
import Spinner from '../spinner/spinner';
import styles from './standardModal.module.css';

interface Props {
	onOk?: ()=> void;
	onCancel?: ()=> void;
	onAfterClosed?: ()=> void;
	visible: boolean;
	title?: string | React.ReactNode;
	children: React.ReactNode;
	customFooter?: React.ReactNode;
	okBtnLabel?: string | React.ReactNode;
	cancelBtnLabel?: string | React.ReactNode;
	width?: number | string;
	closeOnMaskClick?: boolean;
	className?: string;
	bodyClassName?: string;
	headerClassName?: string;
	titleClassName?: string;
	modalWrapperClassName?: string;
	rootClassName?: string;
	footerClassName?: string;
	contentWrapperClassName?: string;
	bodyStyle?: React.CSSProperties;
	transparentFooter?: boolean;
	okButtonColor?: ButtonColors;
	cancelButtonColor?: ButtonColors;
	disableOkButton?: boolean;
	noFooter?: boolean;
	loading?: boolean;
	hideCloseIcon?: boolean;
	centered?: boolean;
	/** whether the modal should be unmounted after closed. Default is true. */
	destroyOnClose?: boolean;
	dataKemuMeta?: string;
	getContainer?: (() => HTMLElement | string | null | undefined);
}

const StandardModal = memo((props: Props): React.JSX.Element => {
	const { getContainer, onAfterClosed, bodyStyle } = props;
	const targetContainerRef = useRef<HTMLElement | null>(null);
	const originalContainerTransformRef = useRef<string | null>(null);

	const handleModalClose = useCallback(() => {
		if (targetContainerRef.current && originalContainerTransformRef.current) {
			targetContainerRef.current.style.transform = originalContainerTransformRef.current;
		}
		onAfterClosed?.();
	}, [onAfterClosed]);

	const selectContainer = useCallback((): HTMLElement => {
		const defaultContainer = document.body;
		let targetContainer = defaultContainer;
		if (typeof getContainer === 'function') {
			const container = getContainer();
			if (typeof container === 'string') {
				targetContainer = document.querySelector(container) || targetContainer;
			} else {
				targetContainer = container || targetContainer;
			}
		}

		if (targetContainer !== defaultContainer && !targetContainerRef.current) {
			originalContainerTransformRef.current = targetContainer.style.transform;
			targetContainer.style.transform = `translate(0px, 0px)`;
		}

		targetContainerRef.current = targetContainer;
		return targetContainer;
	}, [getContainer]);

	// WARNING: For some reason, the handleModalClose function is not 
	// called when the modal is closed. This is just a workaround to restore 
	// the parent's style in that scenario.
	useEffect(() => {
		return () => {
			if (targetContainerRef.current && originalContainerTransformRef.current !== null) {
				targetContainerRef.current.style.transform = originalContainerTransformRef.current;
			}
		};
	}, [onAfterClosed]);

	return (
		<Modal
			maskClosable={props.closeOnMaskClick}
			closable={!props.hideCloseIcon}
			width={props.width}
			open={props.visible}
			keyboard={false}
			styles={{
				body: props.bodyStyle || { padding: 'unset' },
			}}
			onOk={props.onOk}
			onCancel={props.onCancel}
			footer={null}
			centered={props.centered}
			// wrapClassName={props.modalWrapperClassName}
			destroyOnClose={props.destroyOnClose !== undefined ? props.destroyOnClose : true}
			className={props.className}
			afterClose={handleModalClose}
			getContainer={selectContainer}
			rootClassName={props.rootClassName}
			classNames={{
				content: styles.ModalContent,
				wrapper: classNames(styles.ModalWrapper, props.modalWrapperClassName),
			}}
		>
			<Spinner loading={!!props.loading}>
				<div className={classNames(styles.Modal, props.contentWrapperClassName)} data-kemu-meta={props.dataKemuMeta || 'modal'}>
					{props.title && (
						<div className={classNames(styles.Header, props.headerClassName)}>
							<h5 className={classNames(styles.Title, props.titleClassName)} data-kemu-meta="modal-title">{props.title}</h5>
						</div>
					)}

					<div className={classNames(styles.Body, props.bodyClassName)} data-kemu-meta="modal-body">
						{ props.children }
					</div>

					{!props.noFooter && (
						<div
							data-kemu-meta="modal-footer"
							className={classNames(styles.Footer, props.footerClassName, {
								['transparent']: props.transparentFooter,
							})}>
							{props.customFooter ? (
								props.customFooter
							) : (
								props.cancelBtnLabel ? (
									<Space size={20}>
										<StyledButton data-kemu-meta="modal-cancel-btn" color={props.okButtonColor || 'light'} title={props.cancelBtnLabel} onClick={props.onCancel}/>
										<StyledButton data-kemu-meta="modal-ok-btn" disabled={!!props.disableOkButton} color={props.cancelButtonColor || 'primary'} title={props.okBtnLabel} onClick={props.onOk}/>
									</Space>
								) : (
									<StyledButton data-kemu-meta="modal-ok-btn" color={props.okButtonColor || 'light'} disabled={!!props.disableOkButton} title={props.okBtnLabel} onClick={props.onOk}/>
								)
							)}
						</div>
					)}
				</div>
			</Spinner>
		</Modal>
	);
});

export default StandardModal;
