import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import { useDebouncedCallback } from 'use-debounce';
import classNames from 'classnames';
import styles from './AutoScrollLogs.module.css';
import StyledTextArea from '@components/form-control/styledTextArea/styledTextArea';

type Props = {
  label?: string;
  text: string;
  /** Timeout in ms. defaults to 15000 */
  interactTimeout?: number;
  textAreaClassName?: string;
  rows?: number;
}

/**
 * Displays the given text as logs and automatically scrolls to the bottom if the user has not interacted with the logs in the last 15 seconds.
 */
const AutoScrollLogs = (props: Props) => {
  const { label, text, interactTimeout, textAreaClassName, rows = 10 } = props;
  const [logs, setLogs] = useState<string>('');
  const logsInputRef = useRef<TextAreaRef>(null);
  const autoScrollSetAt = useRef<number>(0);
  const lastScrollAt = useRef<number>(0);


  const handleLogsScroll = useCallback(() => {
    const isAutoScroll = Date.now() - autoScrollSetAt.current < 200;
    if (!isAutoScroll) {
      lastScrollAt.current = Date.now();
    }
  }, []);

  const scrollToBottom = useDebouncedCallback(() => {
    if (logsInputRef.current?.resizableTextArea?.textArea) {
      const textAreaComponent = logsInputRef.current.resizableTextArea.textArea;
      // Used to detect when the user has scrolled manually
      autoScrollSetAt.current = Date.now();
      textAreaComponent.scrollTop = textAreaComponent.scrollHeight;
    }
  }, 100);

  useEffect(() => {
    if (text) {
      let firstLogLine = false;
      setLogs((l) => {
        firstLogLine = l.split('\n').length <= 2;
        return text;
      });

      const elapsed = Date.now() - lastScrollAt.current;

      // If the user hasn't scrolled in the last 15 seconds, scroll to the bottom
      if (elapsed > (interactTimeout || 15000) || firstLogLine) {
        scrollToBottom();
      }
    }
  }, [text, scrollToBottom, interactTimeout]);

  return (
    <StyledTextArea
      ref={logsInputRef}
      onScroll={handleLogsScroll}
      className={classNames(styles.Logs, textAreaClassName)}
      readOnly
      value={logs}
      label={label}
      rows={rows}
      preventResize
    />
  );
};

export default AutoScrollLogs;
