import React, { forwardRef, useMemo, useState } from 'react';
import Icon from '@ant-design/icons';
import classNames from 'classnames';
import styles from './BaseServiceIcon.module.css';
import InjectSvg from '@components/InjectSvg/InjectSvg';
import { calculateWidgetColors } from '@components/gates/common';
import GateIcon from '@components/gateIcon/gateIcon';
import { ReactComponent as DefaultServiceIcon } from '@src/assets/img/gates/hubService.svg';

type Props = {
  svgIcon?: string;
  color?: string;
  defaultColor?: string;
  className?: string;
  selected?: boolean;
}

const DefaultServiceColorVar = '--kemu-color-widget-type-custom';

const BaseServiceIcon = (props: Props) => {
  const { svgIcon, color, defaultColor, className, selected } = props;
  const [iconTarget, setIconTarget] = useState<HTMLElement | null>(null);

  const colors = useMemo(() => {
		const docStyle = getComputedStyle(document.body);
    const widgetColor = color || defaultColor;
    const customWidgetColor = docStyle.getPropertyValue(DefaultServiceColorVar);
		return calculateWidgetColors(widgetColor || customWidgetColor);
	}, [color, defaultColor]);


  return (
    <div
      className={classNames(styles.Icon, className, {
        [styles.Selected]: selected,
      })}
      ref={e => setIconTarget(e)}
      style={{ backgroundColor: colors.content }}
    >
      {svgIcon ? (
        <InjectSvg
          contents={svgIcon}
          fill={colors.text}
          targetElement={iconTarget}
        />
      ) : (
        <GateIcon
          style={{ backgroundColor: colors.content }}
          icon={<Icon component={DefaultServiceIcon} />}
        />
      )}
    </div>
  );
};

export default BaseServiceIcon;
