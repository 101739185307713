import { useCallback, useEffect, useRef } from 'react';
import kemuEventHistory, { SourceInfo, type WidgetInvokedCallback } from '@kemu-io/kemu-core/common/eventHistory';
import { useSelector } from 'react-redux';
import { selectLogicMapperSettings } from '@src/features/interface/interfaceSlice';

const useWidgetInvokeMonitor = (
  thingId: string,
  widgetId: string,
  timeout: number,
  callback: (wasInvoked: boolean, source?: SourceInfo, disabled?: boolean) => void,
) => {
  const timeoutMapRef = useRef<Record<string, NodeJS.Timeout | null>>({});
  const lastInvokedValueRef = useRef<boolean | null>(null);
  const lastEventSourceRef = useRef<SourceInfo | null>(null);
  const currentSettings = useSelector(selectLogicMapperSettings);
  const enabled = currentSettings.showConnectionAnimations;

  // Called when the widget is invoked
  const handleWidgetInvoked: WidgetInvokedCallback = useCallback((data) => {
    const { sourceInfo, currentTime, prevTime, targetPort } = data;
    if (!targetPort) { return; }
    const currentTimer = timeoutMapRef.current[targetPort];
    if (currentTimer) {
      clearTimeout(currentTimer);
      timeoutMapRef.current[targetPort] = null;
    }

    // Wait the same amount of time as the timeout before calling the callback
    timeoutMapRef.current[targetPort] = setTimeout(() => {
      if (lastInvokedValueRef.current !== false || lastEventSourceRef.current?.widgetId !== sourceInfo?.widgetId) {
        callback(false, sourceInfo);
        lastInvokedValueRef.current = false;
        lastEventSourceRef.current = sourceInfo || null;
      }
    }, timeout);

    if (lastInvokedValueRef.current !== true || lastEventSourceRef.current?.widgetId !== sourceInfo?.widgetId) {
      callback(true, sourceInfo);
      lastEventSourceRef.current = sourceInfo || null;
      lastInvokedValueRef.current = true;
    }
  }, [timeout, callback]);

  useEffect(() => {
    let unsubscribe: (() => void) | undefined;
    const lastTimers = timeoutMapRef.current;

    if (enabled) {
      unsubscribe = kemuEventHistory.onWidgetInvoked(thingId, widgetId, handleWidgetInvoked);
    } else {
      callback(false, undefined, true);
    }

    return () => {
      unsubscribe?.();
      if (lastTimers) {
        // Abort all timers
        Object.values(lastTimers).forEach((timer) => {
          if (timer) {
            clearTimeout(timer);
          }
        });
      }
    };
  }, [thingId, widgetId, handleWidgetInvoked, timeout, enabled, callback]);
};

export default useWidgetInvokeMonitor;
