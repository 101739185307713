import React, { memo, useEffect } from 'react';
import { isImageDataType } from '@kemu-io/kemu-core/common/utils';
import { DataType } from '@kemu-io/hs-types';
import useGateParentEventDetector, { ParentEventResponse } from '@hooks/useGateParentEventDetector';

interface Props {
	/** invoked when ImageData type events arrive */
	onImageData?: (imageData: ImageData, sourceWidgetId: string) => void;
	onNumericValue?: (value: number | string, sourceWidgetId: string) => void;
	/** Invoked regardless of the event type */
	onEvent?: (event: ParentEventResponse) => void;
	recipeId: string;
	blockRecipeId: string;
	gateId: string;
}

/** a faster way to evaluate if a value is NaN */
const fastIsNaN = (value: number): boolean => {
	return !(value <= 0) && !(value > 0);
};

/**
 * Intercepts widget's onParentEvent(s) and pipes data back to parent component.
 * Implemented in this way to prevent-re rendering of canvas
 */
const ParentEventHandler = (props: Props): null => {
	const { recipeId, blockRecipeId,  gateId, onNumericValue, onImageData, onEvent } = props;
	const event = useGateParentEventDetector(recipeId, blockRecipeId, gateId);

	useEffect(() => {
		if (event) {
			const allowedTypes = [DataType.Number, DataType.Boolean, DataType.Anything];
			const isNumeric = !fastIsNaN(Number(event.data.value));
			const evtValue = event.data.value;

			if (onEvent) { onEvent(event); }

			const isImage = !isNumeric && isImageDataType(evtValue);
			if (allowedTypes.includes(event.data.type) && isNumeric && !isImage) {
				onNumericValue?.(String(event.data.value as string), event.sourceGate.id);
			} else if (event.data.type === DataType.ImageData || isImage) {
				const imageData = event.data.value as ImageData;
				onImageData?.(imageData, event.sourceGate.id);
			}
		}

	}, [event, onNumericValue, onImageData, onEvent]);

	return null;
};

export default memo(ParentEventHandler);
