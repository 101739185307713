import React from 'react';
import Icon from '@ant-design/icons';
import RecipeLoadProcessor from '@kemu-io/kemu-core/widgets/recipeLoad/index.js';
import { WidgetPortContext } from '@kemu-io/kemu-core/types';
import {
  GateUI,
  GetPortsInformationFunction
} from '../index.js';
import { buildOutputPortsDescription } from '../common';
import styles from './RecipeLoad.module.css';
import { ReactComponent as RecipeLoadIcon } from '@src/assets/img/gates/recipe-load-icon.svg';
import GateIcon from '@components/gateIcon/gateIcon';

// type Props = GateUIProps

const RecipeLoad = () => {
  return (
    <div className={styles.RecipeLoadWrapper}>
      <Icon component={RecipeLoadIcon} width={49} height={49}/>
    </div>
  );
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={RecipeLoadIcon} />}/>
	);
};


const getPortsInformation: GetPortsInformationFunction = (state, widgetInfo) => {
  const portContext: WidgetPortContext = {
		recipePoolId: widgetInfo.recipePoolId,
		recipeType: widgetInfo.recipeType,
		thingRecipeId: widgetInfo.thingRecipeId,
		widgetId: widgetInfo.id,
	};
	const outputNames = RecipeLoadProcessor.getOutputNames(state, portContext);
	const outputs = buildOutputPortsDescription(outputNames, 0.5);

	return {
		inputs: [],
		outputs,
	};
};

export default {
	getPortsInformation,
  hasTitle: false,
  getWidgetTitle: (intl) => intl.formatMessage({ id: 'Widget.RecipeLoad.ShortTitle' }),
	BarIcon: GateBarIcon,
	Element: RecipeLoad,
	getGatesBarTitle: (intl) => intl.formatMessage({ id: 'Widget.RecipeLoad.Title' }),
} as GateUI;
