import React, { useEffect, useMemo, useState } from 'react';
import PasswordValidator from 'password-validator';
import { useIntl } from 'react-intl';
import StyledInput from '../styledInput/styledInput';

interface Props {
	min?: number;
	max?: number;
	lowerCase?: boolean;
	upperCase?: boolean;
	symbols?: number;
	digits?: number;
	preventSpaces?: boolean;
	placeholder?: string;
	i18nPlaceholderId?: string;
	i18nPlaceholderDefaultMessage?: string;
	name?: string;
	value?: string;
	errorMessage?: string;
	onChange?: (text: string, hasErrors?: string) => void;
}

const PasswordMeter = (props: Props): React.JSX.Element => {
	const schema = useMemo(() => new PasswordValidator(), []);
	const [currentText, setCurrentText] = useState(props.value);
	const [errorMessage, setErrorMessage] = useState<string | undefined>(props.errorMessage);
	const intl = useIntl();

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const text = event.currentTarget.value || '';

		if (props.min) { schema.is().min(props.min); }
		if (props.max) { schema.is().max(props.max); }
		if (props.upperCase) { schema.has().uppercase(); }
		if (props.lowerCase) { schema.has().lowercase(); }
		if (props.symbols) { schema.has().symbols(props.symbols); }
		if (props.digits) { schema.has().digits(props.digits); }
		if (props.preventSpaces) { schema.not().spaces(); }

		setCurrentText(text);

		const errors = schema.validate(text, { list: true });
		let errMsg;
		if (Array.isArray(errors)) {
			if (errors.includes('uppercase')) { errMsg = (intl.formatMessage({ id: 'Screen.SignUp.Password.UpperCase', defaultMessage: 'Must have uppercase letters' })); }
			if (errors.includes('lowercase')) { errMsg = (intl.formatMessage({ id: 'Screen.SignUp.Password.LowerCase', defaultMessage: 'Must have lowercase letters' })); }
			if (errors.includes('min')) { errMsg = (intl.formatMessage({ id: 'Screen.SignUp.Password.MinRule', defaultMessage: 'Minimum length is {total} characters' }, { total: props.min })); }
			if (errors.includes('max')) { errMsg = (intl.formatMessage({ id: 'Screen.SignUp.Password.MaxRule', defaultMessage: 'Maximum length is {total} characters' }, { total: props.max })); }
			if (errors.includes('digits')) { errMsg = (intl.formatMessage({ id: 'Screen.SignUp.Password.Digits', defaultMessage: 'Must have at least {total} digits' }, { total: props.digits })); }
			if (errors.includes('spaces')) { errMsg = (intl.formatMessage({ id: 'Screen.SignUp.Password.Spaces', defaultMessage: 'Should not have spaces' })); }
			if (errors.includes('symbols')) { errMsg = (intl.formatMessage({ id: 'Screen.SignUp.Password.Symbols', defaultMessage: 'Must have at least {total} symbol{suffix}' }, { total: props.symbols, suffix: (props?.symbols || 0) > 1 ? 's' : '' })); }

			if (!errors.length || !text) { setErrorMessage(undefined); }
		}

		// Only notify if the password is valid
		props.onChange?.(text, errMsg);
	};

	useEffect(() => {
		setErrorMessage(props.errorMessage);
	}, [props.errorMessage]);

	useEffect(() => {
		setCurrentText(props.value);
	}, [props.value]);

	return (
		<div style={{ width: '100%' }}>
			<StyledInput
				i18nPlaceholderId={props.i18nPlaceholderId}
				i18nPlaceholderDefaultMessage={props.i18nPlaceholderDefaultMessage}
				type="password"
				name={props.name}
				onChange={handleChange}
				value={currentText}
				errorMessage={errorMessage}
			/>
		</div>
	);
};

export default PasswordMeter;
