import { useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { GetServicesResponse } from '@kemu-io/hs-types';
import useKemuHubLink from './useHubLink';

const getDefaultServicesResponse = (): GetServicesResponse => ({
  available: [],
  installed: [],
  failed: [],
});

/**
 * Uses the active hub connection to fetch a list of services running on the hub.
 * It also responds to `service-change` commands and refreshes the list of services.
 * If the hub connection is not available, it will return an empty list.
 */
const useActiveHubServices = () => {
  const { connector, status } = useKemuHubLink();
  const [runningServices, setRunningServices] = useState<GetServicesResponse>(getDefaultServicesResponse());

  const isAcknowledged = status === 'acknowledged';

	const onServicesChanged = useDebouncedCallback(async (updatedList: GetServicesResponse) => {
		setRunningServices(updatedList);
	}, 100);

	const fetchNewServices = useDebouncedCallback(async (bypassCache = false) => {
		const alreadyCached = connector.areServicesCached();
		let services: GetServicesResponse;

		if (!alreadyCached || bypassCache) {
			services = (await connector.getServices());
		} else {
			services = connector.getCachedServices();
		}

		setRunningServices(services);
	}, 100);

  useEffect(() => {

    const removeSubscription = connector.onCommand('services-changed', onServicesChanged);

		if (isAcknowledged) {
			fetchNewServices();
		} else {
      setRunningServices(c => c.available.length !== 0 ? getDefaultServicesResponse() : c);
    }

		return () => {
			onServicesChanged.cancel();
			fetchNewServices.cancel();
      removeSubscription();
		};
	}, [
		connector,
		status, // < == force reloading services if the connection status changes (as opposed to just the acknowledgement status)
		isAcknowledged,
		onServicesChanged,
		fetchNewServices,
	]);

	const memoizedServices = useMemo(() => ({
		services:	runningServices,
		/**
		 * A function to force a refresh of the services list, bypassing the cache.
		 */
		forceRefresh: () => fetchNewServices(true),
	}), [runningServices, fetchNewServices]);

  return memoizedServices;
};

export default useActiveHubServices;
