import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import Badge from 'antd/lib/badge';
import { Position } from '@kemu-io/kemu-core/types';
import { SerializableServiceInfo } from '@kemu-io/hs-types';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import BaseServiceIcon from '../../BaseServiceIcon/BaseServiceIcon';
import styles from './ServiceButton.module.css';
import TruncatedText from '@components/truncatedText/truncatedText';
import { DroppableTypes } from '@common/constants';
import { calculateWidgetColors } from '@components/gates/common';

type Props = {
  onClick: (service: SerializableServiceInfo, variantId?: string) => void;
  onAddService: (info: {
    name: string,
    version: string,
    variant?: string
  },
  dropLocation: Position
) => void;
	onHideLaunchpad: () => void;
  service: SerializableServiceInfo;
  disabled?: boolean;
  variantId?: string;
  isSelected: boolean;
  disableDragging?: boolean;
  noBadge?: boolean;
  wrapperClassName?: string;
  iconClassName?: string;
}

const DefaultServiceColorVar = '--kemu-color-widget-type-custom';

const ServiceButton = (props: Props) => {
  const {
    service, onAddService, onHideLaunchpad,
    disabled, onClick, disableDragging, noBadge, variantId,
  } = props;
  const [textExpanded, setTextExpanded] = useState(false);
  const matchingVariant = service.variants?.find(v => v.id === variantId);

  const textVisibilityChanged = useCallback((visible: boolean) => {
		setTextExpanded(visible);
	}, []);

  const colors = useMemo(() => {
		const docStyle = getComputedStyle(document.body);
    const widgetColor = matchingVariant?.color || service.color;
    const customWidgetColor = docStyle.getPropertyValue(DefaultServiceColorVar);
		return calculateWidgetColors(widgetColor || customWidgetColor);
	}, [service.color, matchingVariant]);


  const [{ isDragging }, drag, preview] = useDrag<any, Position, any>(() => ({
    type: DroppableTypes.CustomWidget,
		canDrag: !disabled && !disableDragging,
    item: {
			color: service.color,
			textColor: colors.text,
			isHubService: true,
			contentColor: colors.content,
			svgIcon: service.svgIcon,
		},
    end: (item, monitor) => {
      const dropLocation = monitor.getDropResult();
			if (dropLocation) {
				onAddService({
          name: service.name,
          version: service.version,
          variant: variantId,
        }, {
					x: dropLocation.x,
					y: dropLocation.y,
				});
			}
    },

    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }), [service, onAddService]);

  /** shows a preview image and hides the launchpad while dragging */
  useEffect(() => {
		const emptyImage = getEmptyImage();
		preview(emptyImage);
		if (isDragging) {
			onHideLaunchpad();
		}
	}, [preview, isDragging, onHideLaunchpad]);

  return (
    <div
      className={
        classNames(styles.ServiceButton, {
          'text-expanded': textExpanded,
          'disabled': props.disabled
        }, props.wrapperClassName)
      }
      data-kemu-meta={`hub-service-${service.name}-${service.version}`}
      ref={drag}
      onClick={() => onClick(service, variantId)}
    >
      <Badge
        count={'dev'}
        style={{
          display: !service.devMode || noBadge ? 'none': 'block'
        }}
        size='small'
        showZero
        offset={[-5, 0]}
        color='red'
      >
        <BaseServiceIcon
          svgIcon={matchingVariant?.svgIcon || service.svgIcon}
          color={matchingVariant?.color || service.color}
          selected={props.isSelected}
        />
      </Badge>

      <div className={classNames(styles.ServiceName, 'noselect')}>
        <label>
          <TruncatedText
            text={`${matchingVariant?.name || service.title || service.name}`}
            maxChars={24}
            onVisibilityChange={textVisibilityChanged}
            showFullOn="hover"
            // suffix={<div>({service.version})</div>}
          />
        </label>
      </div>
    </div>
  );
};

export default ServiceButton;
