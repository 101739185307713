import React from 'react';
import { Button, ButtonProps, Input, Tooltip, type InputProps } from 'antd';
import classNames from 'classnames';
import StyledLabel from '../styledLabel/styledLabel';

type Props = InputProps & {
  label?: React.ReactNode;
	tooltip?: string;
	buttonIcon: React.ReactElement;
	buttonProps?: ButtonProps;
}

const InputWithButtonSuffix = (props: Props) => {
  const { label, className, tooltip, buttonIcon, buttonProps, ...inputProps } = props;
  return (
		<>
			{props.label && (
				<StyledLabel text={props.label} />
			)}

			<Input
				className={classNames(className)}
				suffix={
					<Tooltip open={tooltip === undefined ? false : undefined} title={tooltip}>
						<Button type="link" icon={buttonIcon} {...buttonProps} />
					</Tooltip>
				}
				{...inputProps}
			/>
		</>
	);
};

export default InputWithButtonSuffix;
