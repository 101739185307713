import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { ReactElement, useCallback, useState } from 'react';
import { Menu, Layout, theme } from 'antd';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import Icon, {
	FileOutlined,
	HomeOutlined,
	ExportOutlined,
	SaveOutlined,
	DownloadOutlined,
} from '@ant-design/icons';
// import { ReactSVG } from 'react-svg';
import { useNavigate } from 'react-router-dom';
import { ThingCategory , CustomWidgetVariant } from '@kemu-io/kemu-types';
import classNames from 'classnames';
import LogoAndText from '../../assets/img/kemu-logo-text.png';
import Logo from '../../assets/img/kemu-logo.png';
import { ReactComponent as RecipeIcon } from '../../assets/img/menus/recipe.svg';
import { currentRecipePoolId, selectedBlock, selectVisibleGroup, unsavedChanges } from '../../features/Workspace/workspaceSlice';
import StatusIndicator from '../statusIndicator/statusIndicator';
import { getStoredConfig, setStoredConfig } from '../../common/utils';
import globals from '../../common/globals';
import routes from '../../common/routes/index';
import ExportRecipeDialog from '../ExportRecipeDialog/ExportRecipeDialog';
import styles from './mainMenu.module.css';
import useTranslation from '@hooks/useTranslation';
import { selectCurrentWidget } from '@src/app/reducers/widget/widgetSlice';
import { saveWidgetAction } from '@src/app/reducers/widget/saveWidgetReducer';
import { updateWidgetAction } from '@src/app/reducers/widget/updateWidgetReducer';
import { storeRecipeAction } from '@src/features/interface/reducers/storeRecipeReducer';
import AccountMenu from '@components/AccountMenu/AccountMenu';

const { Sider } = Layout;
interface Props {
	collapsed: boolean
}

interface MenuConfig {
	openedMenus: string[];
}

enum MarketplaceTarget {
	PublicRecipes,
	MyRecipes,
	MyTutorials,
}

const CONFIG_NAME = 'selectedMenus';
const defaultConfig: MenuConfig = {
	openedMenus: ['apps']
};


function MainMenu ({ collapsed }: Props): ReactElement {
	const dispatch = useDispatch();
	// const hbList = useSelector(hardwareBlocks);
	const [openedItems, setOpenedItems] = useState<MenuConfig>(getStoredConfig(CONFIG_NAME, defaultConfig));
	const { token: { colorBgContainer } } = theme.useToken();
	const navigate = useNavigate();
	const t = useTranslation('Menu');
	const visibleGroup = useSelector(selectVisibleGroup);
	const currentBlock = useSelector(selectedBlock);
	const showRedDot = useSelector(unsavedChanges);
	const recipePoolId = useSelector(currentRecipePoolId);
	const currentWidget = useSelector(selectCurrentWidget);
	const [showExportDialog, setShowExportDialog] = useState(false);

	const openMarketplace = useCallback((target: MarketplaceTarget) => {
		if (target === MarketplaceTarget.PublicRecipes) { navigate(routes.marketplace.getPublicationListRoute('recipe')); };
		if (target === MarketplaceTarget.MyRecipes) { navigate(routes.marketplace.getMyRecipesRoute()); };
		if (target === MarketplaceTarget.MyTutorials) { navigate(routes.marketplace.getMyTutorialsRoute()); };
	}, [navigate]);

	const createNewRecipe = useCallback(() => {
		window.open(`https://${globals.KEMU_APP_DOMAIN}`, '_blank');
	}, []);

	const createNewRecipeHere = useCallback(() => {
		window.open(`https://${globals.KEMU_APP_DOMAIN}`, '_self');
	}, []);


	const iconStyle = { fontSize: collapsed ? 26 : 20 };
	const onSVGInjection = (svg: SVGElement, category: ThingCategory) => {
		let size = iconStyle.fontSize;
		if (category === ThingCategory.Virtual) { size = 20; }
		svg.setAttribute('style', `width: ${size}px; height: ${size}px`);
	};

	// const getDynamicSVG = (icon: string, category: ThingCategory) => {
	// 	return () => <ReactSVG src={icon} wrapper="span" beforeInjection={svgEl => onSVGInjection(svgEl, category)}/>;
	// };

	const handleExportRecipe = useCallback(() => {
		setShowExportDialog(true);
	}, []);

	const handleExportDialogClose = useCallback(() => {
		setShowExportDialog(false);
	}, []);

	// Detect if the widget already exists, and if so, call update instead
	const saveWidgetHandler = useCallback(() => {
		if (currentBlock?.recipeId && visibleGroup?.groupId && recipePoolId) {

			if (!currentWidget) {
				dispatch(saveWidgetAction({
					recipeId: recipePoolId,
					thingId: currentBlock?.recipeId,
					widgetIdInRecipe: visibleGroup?.groupId,
					variant: CustomWidgetVariant.Group,
				}));
			} else {
				dispatch(updateWidgetAction({
					widgetDbId: currentWidget,
					recipeId: recipePoolId,
					thingId: currentBlock.recipeId,
					widgetRecipeId: visibleGroup.groupId,
					variant: CustomWidgetVariant.Group,
				}));
			}
		}
	}, [dispatch, visibleGroup, currentWidget, currentBlock, recipePoolId]);

	const saveRecipeHandler = useCallback(() => {
		// FIXME: restore showRedDot
		if (/*showRedDot && */ recipePoolId) {
			dispatch(storeRecipeAction({
				recipePoolId: recipePoolId,
				navigate,
			}));
		}
	}, [dispatch, /* showRedDot,*/ recipePoolId, navigate]);

	const onOpenChange = (keys: React.ReactText[]) => {
		// when the menu collapses, keys is an empty array and it would delete everything 
		if (!collapsed) {
			setOpenedItems({
				openedMenus: keys as string[]
			});

			setStoredConfig<MenuConfig>(CONFIG_NAME, {
				openedMenus: keys as string[]
			} as MenuConfig, true);
		}
	};

	const inDevServer = window.location.host.includes('dev.kemu.io');
	return (
		<Sider
			style={{ background: colorBgContainer }}
			width={250}
			trigger={null}
			collapsible
			collapsed={collapsed}
		>
			<div className={styles.SidebarContainer}>
				<div>
					{/* Logo */}
					<div className={styles.SidebarBrand}>
						<a href="/">
							{!collapsed ? (
								<img alt="image" src={LogoAndText} className={styles.HeaderLogo} />
							) : (
								<img alt="image" src={Logo} className={styles.CollapsedHeaderLogo} />
							)}
						</a>
					</div>

					{/* Menu */}
					<Menu
						onOpenChange={onOpenChange}
						triggerSubMenuAction="click"
						defaultOpenKeys={!collapsed ? openedItems.openedMenus: []}
						mode="inline"
						selectable={false}
						subMenuCloseDelay={0.35}
						className={classNames(styles.Menu, collapsed && styles.Collapsed)}
						items={[
							{
								key: 'home',
								icon: <HomeOutlined style={{ fontSize: 26 }} />,
								title: collapsed ? t('Home') : '',
								label: t('Home'),
								className: styles.LargeMenu,

								children: [
									{
										key: 'new-recipe-here',
										icon: <FileOutlined />,
										label: t('NewRecipeHere', 'New Recipe'),
										onClick: createNewRecipeHere,
									},
									{
										key: 'new-recipe',
										icon: <ExportOutlined />,
										label: t('NewRecipeTab', 'New Recipe (new tab)'),
										onClick: createNewRecipe,
									},
									{
										key: 'my-recipes-item',
										icon: <Icon component={RecipeIcon} />,
										label: t('MyRecipes'),
										onClick: () => openMarketplace(MarketplaceTarget.MyRecipes),
									},
									{
										key: 'export-recipe',
										icon: <DownloadOutlined />,
										label: t('ExportRecipe'),
										onClick: handleExportRecipe,
									},
									// {
									// 	key: 'marketplace-item',
									// 	icon: <ShoppingCartOutlined />,
									// 	label: t('Marketplace'),
									// 	onClick: () => openMarketplace(MarketplaceTarget.PublicRecipes),
									// },
								]
							},
							{
								key: 'save',
								className: styles.LargeMenu,
								label: visibleGroup ? t('SaveWidgetTooltip', 'Save Widget') : t('SaveRecipeTooltip', 'Save Recipe'),
								icon: <SaveOutlined style={{ fontSize: 26 }} />,
								// icon: (
								// 	<Icon
								// 		component={visibleGroup ? SaveWidgetIcon : SaveRecipeIcon}
								// 		style={{ fontSize: 26 }}
								// 	/>
								// ),
								onClick: visibleGroup ? saveWidgetHandler : saveRecipeHandler,
							}
						]}
					/>
				</div>

				<div className={styles.PushDownItems}>
					{/* User Menu */}
					<AccountMenu className={styles.ProfileMenu} />
					<StatusIndicator />
					<span
						className={classNames(styles.VersionBox, inDevServer && styles.Staging)}
					>
						v{globals.WEB_APP_VERSION}
					</span>
				</div>

				<ExportRecipeDialog
					visible={showExportDialog}
					onClose={handleExportDialogClose}
				/>
			</div>
		</Sider>
	);
}


export default MainMenu;
