import React from 'react';
import { IntlProvider } from 'react-intl';

import enMessages from './messages/en.json';
import esMessages from './messages/es.json';
import { createGlobalIntlInstance } from './globalIntl';

const allMessages: Record<string, any> = {
  en: enMessages,
  es: esMessages,
};

type Props = {
  language: string;
  children: React.ReactNode;
}

export default function I18nProvider (props: Props) {
  const locale = props.language; // useSelector(({ i18n }) => i18n.lang);
  const messages = allMessages[locale];
  createGlobalIntlInstance(locale, messages);

  return (
      <IntlProvider locale={locale} messages={messages}>
        {props.children}
      </IntlProvider>
  );
}
