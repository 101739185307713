import { Amplify } from '@aws-amplify/core';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import globals from './globals';
import authSession from './authSession';
import Logger from '@common/logger';

const logger = Logger('amplify');

export const getCustomHeader = async (): Promise<Record<string, string>> => {
  const session = authSession.getCurrentSession();
  if (!session.token) { return {}; }

  let token: string = session.token;
  if (authSession.hasTokenExpired()) {
    console.log('Token has expired. Forcing refresh...');
    const user = await Auth.currentAuthenticatedUser() as CognitoUser;
    const accessToken = user.getSignInUserSession()?.getAccessToken();
    if (!accessToken) {
      return {};
    }

    token = accessToken.getJwtToken();
    authSession.updateSessionToken(token, Number(accessToken.getExpiration()));
  }

  return {
    Authorization: `Bearer ${token}`
  };
};

const appConfig = {
  // Region where Amazon Cognito project was created   
  aws_cognito_region: globals.COGNITO_REGION,
  aws_user_pools_id: globals.COGNITO_POOL_ID,
  aws_user_pools_web_client_id: globals.COGNITO_CLIENT_ID,
  cookieStorage: {
    domain: globals.SHARED_DOMAIN,
    secure: true,
    path: '/',
    expires: 365,
  },
  API: {
    endpoints: [
      {
        // Allow things to produce http requests with an Authorization header.
        name: globals.AUTHENTICATED_API_NAME,
        endpoint: '',
        custom_header: getCustomHeader,
      },
      {
        name: globals.TUTORIAL_API_NAME,
        endpoint: globals.TUTORIAL_API,
        // endpoint: 'http://localhost:3007/development',
        custom_header: getCustomHeader
      },
      {
        name: globals.TUTORIAL_STATIC_NAME,
        endpoint: globals.TUTORIAL_STATIC,
        custom_header: getCustomHeader
      },
      {
        name: globals.WIDGET_API_NAME,
        endpoint: globals.WIDGET_API,
        // endpoint: 'http://localhost:3021',
        custom_header: getCustomHeader
      },
      {
        // Kemu things
        name: globals.THING_API_NAME,
        endpoint: globals.THING_API,
        // endpoint: 'http://localhost:3017/development',
        custom_header: getCustomHeader
      },
      {
        name: globals.RECIPE_API_NAME,
        endpoint: globals.RECIPE_API,
        // endpoint: 'http://localhost:3001/development',
        custom_header: getCustomHeader
      },

      {
        name: globals.MARKETPLACE_API_NAME,
        endpoint: globals.MARKETPLACE_API,
        // endpoint: 'http://localhost:3005',
        custom_header: getCustomHeader
      },
      {
        name: globals.USER_API_NAME,
        endpoint: globals.USER_API,
        // endpoint: 'http://localhost:3009',
        custom_header: getCustomHeader
      },
      {
        name: globals.ORGANIZATION_API_NAME,
        endpoint: globals.ORGANIZATION_API,
        // endpoint: 'https://kemu-organization-api-staging.dev.service.kemu.io',
        // endpoint: 'http://localhost:3011/development',
        custom_header: getCustomHeader
      }
    ]
  }
};

const setup = () => {
  logger.log(`Configuring auth...`);
  Amplify.configure(appConfig);
};

export default setup;
