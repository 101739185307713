import { Auth } from '@aws-amplify/auth';
import { Hub, HubCapsule } from '@aws-amplify/core';
import { useEffect, useRef, useState } from 'react';
import authSession from '../authSession';
import {
  CognitoUserExt,
  UserSessionTokenContext
} from '../context/UserSessionContext/UserSessionContext.tsx';
import Logger from '@common/logger';

const logger = Logger('useAuthenticatedUser');

/**
 * This hook is intended to be used by a context provider, not directly.
 */
const useAuthenticatedUser = (): UserSessionTokenContext => {
  const [currentUser, setCurrentUser] = useState<UserSessionTokenContext>({ state: 'Unknown', jwtToken: null });
  const removeAuthListener = useRef<(() => void) | null>(null);

  useEffect(() => {
    let abortProcess = false;
    const updateUser = async (event?: HubCapsule) => {
      const eventName = event?.payload.event;
      logger.log('Auth event: ', event);
      try {
        if (
          !event
          || eventName === 'signIn_start'
          || eventName === 'signIn'
          || eventName === 'cognitoHostedUI'
        ) {
          // Bypass caching the very first time it signs in.
          const user = await Auth.currentAuthenticatedUser({ bypassCache: true }) as CognitoUserExt;
          if (abortProcess) {
            console.log('aborting process');
            return;
          }

          const userSession = user.getSignInUserSession();
          if (userSession) {
            const accessToken = userSession.getAccessToken();
            const token = accessToken.getJwtToken();
            const expiresAt = Number(accessToken.getExpiration());
            authSession.updateSessionToken(token, expiresAt);
            setCurrentUser({ user, state: 'LoggedIn', jwtToken: token });
          }
        }


        if (eventName === 'signOut' || eventName === 'tokenRefresh_failure') {
          setCurrentUser({ state: 'NotLoggedIn', user: undefined, jwtToken: null });
          authSession.clearSession();
        }

        if (eventName === 'tokenRefresh') {
          // IMPORTANT: `currentSession()` does not return a CognitoUser, hence we access
          // the expiration time differently from above
          const user = await Auth.currentSession();
          const token = user.getAccessToken().getJwtToken();
          const expiresAt = Number(user.getIdToken().getExpiration());
          authSession.updateSessionToken(token, expiresAt);
        }

        // Intercept changes to the user's attributes (ie. when an account type is selected)
        if (eventName === 'updateUserAttributes') {
          const user = await Auth.currentAuthenticatedUser() as CognitoUserExt;
          setCurrentUser((currentUser) => ({
            ...currentUser,
            user,
          }));
        }

      } catch (e) {
        // currentAuthenticatedUser throws an Error if not signed in
        logger.log('Error getting user: ', e);
        setCurrentUser({ state: 'NotLoggedIn', user: undefined, jwtToken: null });
        authSession.clearSession();
      }
    };

    // Listen for login/signup events.
    removeAuthListener.current = Hub.listen('auth', updateUser);
    if (currentUser.state === 'Unknown') {
      Hub.dispatch('auth', { event: 'signIn_start' });
    }

    return () => {
      abortProcess = true;
      removeAuthListener.current?.();
    };
  }, [currentUser]);

  return currentUser;
};

export default useAuthenticatedUser;
