import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Position, WidgetType } from '@kemu-io/kemu-core/types';
import { useDrag } from 'react-dnd';
import { usePreviewStateFull } from 'react-dnd-preview/dist/cjs/usePreview.js';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { usePreview } from 'react-dnd-preview';
import classNames from 'classnames';
// import { useSelector } from 'react-redux';
// import { selectCanvasZoom } from '../../logicMapperSlice';
import { GateUI } from '../../../../components/gates/index';
import { DroppableTypes } from '../../../../common/constants';
import { getCategoryFromType } from '../../../../common/widgetCategories';
import styles from './WidgetButton.module.css';

interface Props {
	widgetInfo: GateUI;
	type: WidgetType;
	onAddGate: (type: string, position: Position) => void;
}

const horizontalWidgets: WidgetType[] = [/* WidgetType.input, WidgetType.action */];
const fullBodyWidgets: WidgetType[] = [
	WidgetType.jsonParse,
	WidgetType.base64ToImageData,
	WidgetType.script,
	WidgetType.imageCrop,
	WidgetType.imageResize,
	WidgetType.play,
	WidgetType.imageWarp,
	WidgetType.sequence,
	WidgetType.recipeLoad,
];

const WidgetPreview = (props: { widgetType: WidgetType, widget: GateUI, WidgetIcon: () => React.JSX.Element }) => {
	const { display, style, itemType } = usePreview() as usePreviewStateFull;
	const { widget, widgetType, WidgetIcon } = props;
	const intl = useIntl();
	// const canvasZoom = useSelector(selectCanvasZoom);
  const isHorizontal = horizontalWidgets.includes(widgetType);
	const isFullBody = fullBodyWidgets.includes(widgetType);
	const widgetCategory = getCategoryFromType(widgetType);

	if (!display || itemType !== DroppableTypes.Widget) {
		return null;
  }

	// console.log(`Setting css: ${style.transform} scale(${canvasZoom.toFixed(2)})`);

	return (
		<div
			className={classNames(
				styles.PreviewWrapper,
				`ctgry-${widgetCategory}`,
			)}
			style={{
				...style,
				// transform: `${style.transform} scale(${canvasZoom.toFixed(2)})`,
				// WebkitTransform: `${style.WebkitTransform} scale(${canvasZoom.toFixed(2)})`,
			}}
		>
			{isFullBody ? (
				<div className={styles.FullBody}>
					<div className={styles.FullBodyHeader}>
						<div className={styles.Title}>{widget.getWidgetTitle ? widget.getWidgetTitle(intl) : widgetType}</div>
					</div>
					<WidgetIcon />
				</div>
			) : (
				<>
					{!isHorizontal && (
						<div className={styles.Header}>
							<div className={styles.Title}>{widget.getWidgetTitle ? widget.getWidgetTitle(intl) : widgetType}</div>
						</div>
					)}

					<div className={classNames(styles.Body, {
							[styles.HorizontalBody]: isHorizontal,
					})}>

						{isHorizontal && (
							<div className={classNames(
								styles.HorizontalIcon,
								{ [styles.HorizontalRightIcon]: false /* widgetType === WidgetType.action, */ },
							)} />
						)}
					</div>
				</>
			)}
		</div>
	);
};

const WidgetButton = (props: Props): React.JSX.Element => {
	const { widgetInfo, type, onAddGate } = props;
	const GateBarIcon = widgetInfo.BarIcon;
	const iconWrapperClass = widgetInfo.getBarWrapperClass ? widgetInfo.getBarWrapperClass() : '';
	if (!GateBarIcon) { throw new Error(`Missing BarIcon in widget [${type}]`); }
	const intl = useIntl();
	// Extract the bar title if defined, or use the header instead. Defaults to widget type if none of the previous were defined.
	let title: string | null = widgetInfo.getGatesBarTitle ? widgetInfo.getGatesBarTitle(intl) : null;
	if (!title) { title = widgetInfo.getWidgetTitle ? widgetInfo.getWidgetTitle(intl) : type; }

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [{ isDragging }, drag, preview] = useDrag<any, Position, any>(() => ({
    type: DroppableTypes.Widget,
    // item: { name },
    end: (item, monitor) => {
      const dropLocation = monitor.getDropResult();
			if (dropLocation) {
				onAddGate(type, {
					x: dropLocation.x,
					y: dropLocation.y,
				});
			}
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }), [type, onAddGate]);


	useEffect(() => {
		const emptyImage = getEmptyImage();
		preview(emptyImage);
	}, [preview, isDragging]);

	return (
		<>
			<div
				className={`logic-gate-element ctgry-${getCategoryFromType(type)} ${iconWrapperClass}`}
				data-kemu-meta={`gate-type-${type}`}
				ref={drag}
				/* onClick={() => onAddGate(type)} */
			>
				<GateBarIcon />
				<div className="noselect gate-name">
					<label>{title}</label>
				</div>
			</div>

			{isDragging && (
				<WidgetPreview
					widget={widgetInfo}
					widgetType={type}
					WidgetIcon={GateBarIcon}
				/>
			)}
		</>
	);

};

export default WidgetButton;
