import React, { useCallback, useMemo, useState } from 'react';
import { WidgetType, Position } from '@kemu-io/kemu-core/types';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { CaretLeftOutlined, CaretRightOutlined, DingtalkSquareFilled } from '@ant-design/icons';
import { useResizeDetector } from 'react-resize-detector';
import classNames from 'classnames';
// import ReactPaginate from 'react-paginate';
import { selectedBlock, selectVisibleGroup, setRecipeNeedsSaving } from '../../Workspace/workspaceSlice';
import { addGate } from '../logicMapperSlice';
import ReactPaginate from './paginateWrapper';
import WidgetsLaunchpad from './WidgetsLaunchpad/WidgetsLaunchpad';
import WidgetButton from './WidgetButton/WidgetButton';
import SearchBar from './SearchBar';
import styles from './WidgetsBar.module.css';
import { WIDGETS_BAR_CLASS } from '@common/constants';
import { getCategoryFromType } from '@common/widgetCategories';
import { GateUI, getGatesCollection, GateCollection } from '@components/gates/index';

interface Props {
	recipePoolId: string;
}

const WIDGET_BUTTON_WIDTH = 82;

type AvailableWidgets = Partial<GateCollection>;
const removeUnusedWidgets = (widgets: GateCollection): AvailableWidgets => {
	const { ...remaining } = widgets;
	return remaining;
};

const orderByCategory = (widgets: WidgetType[]) => {
	const alphabeticallySorted = widgets.sort();
	const sorted = alphabeticallySorted.sort((gA, gB) => (getCategoryFromType(gA) > getCategoryFromType(gB) ? 1 : -1));
	// Extract 'custom' types and put them at the beginning of the list
	const customTypes = sorted.filter(gateType => getCategoryFromType(gateType) === 'custom');
	const nonCustomTypes = sorted.filter(gateType => getCategoryFromType(gateType) !== 'custom');
	sorted.splice(0, sorted.length, ...customTypes, ...nonCustomTypes);
	return sorted;
};

// List of available widgets - which should NOT change
const gates = removeUnusedWidgets(getGatesCollection());

const WidgetsBar = (props: Props): React.JSX.Element => {
	const intl = useIntl();
	const openedBlock = useSelector(selectedBlock);
	const dispatch = useDispatch();
	const [filteredGates, setFilteredGates] = useState(Object.keys(gates));
	const [currentPage, setCurrentPage] = useState(1);
	const currentFolder = useSelector(selectVisibleGroup);
	const { ref, width } = useResizeDetector();

	// NOTE: We subtract the widget button width to accommodate the CustomWidgets button which is always visible
	// -1 is to prevent the CustomWidgets button from being counted
	let totalWidgetsInView = Math.floor(((width ?? 0) - WIDGET_BUTTON_WIDTH) / WIDGET_BUTTON_WIDTH) - 1;
	let totalPages = Math.ceil(filteredGates.length / (totalWidgetsInView || filteredGates.length) /* prevent divide by zero */);
	if (totalWidgetsInView < 0) { totalWidgetsInView = 0; }
	if (totalPages < 0) { totalPages = 0; }

	const onAddGate = useCallback((type: string, position: Position) => {
		if (openedBlock) {
			dispatch(addGate({
				blockRecipeId: openedBlock.recipeId,
				recipeId: props.recipePoolId,
				gateType: type as WidgetType,
				position,
				...(currentFolder ? { parentGroupId: currentFolder.groupId } : undefined)
			}));

			dispatch(setRecipeNeedsSaving(true));
		}
	}, [dispatch, openedBlock, currentFolder, props.recipePoolId]);

	// Build a list of paginated & filtered widgets
	const paginatedWidgets = useMemo(() => {
		const preSorted = orderByCategory(filteredGates as WidgetType[]);
		const pageIndex = currentPage - 1;
		const from = pageIndex * totalWidgetsInView;
		const to = Math.min(from + totalWidgetsInView, Object.keys(preSorted).length);
		const gatesToShow: Record<string, GateUI> = {};
		let index = from;
		let added = 0;
		if (openedBlock) {
			// for (let i = from; i < to; i++) {
			while (added < (to - from) && index < preSorted.length) {
				const gateType = preSorted[index];
				const widget = gates[gateType];
				if (widget?.BarIcon) {
					gatesToShow[gateType] = widget;
					added++;
				}

				index++;
			}
		}

		return gatesToShow;
	}, [currentPage, totalWidgetsInView, filteredGates, openedBlock]);

	const onFilterByText = (text: string) => {
		const allGates = Object.keys(gates);
		const filtered = allGates.filter(gateName => {
			const gate = gates[gateName as WidgetType];
			const getTitleFunction = gate?.getGatesBarTitle ?? gate?.getWidgetTitle;
			const title = getTitleFunction ? getTitleFunction(intl) : gateName;
			// Search by gate name and friendly title
			return `${title} ${gateName}`.toUpperCase().includes(text.toLocaleUpperCase());
		});

		setCurrentPage(1);
		setFilteredGates(filtered);
	};

	const onChangePage = (args: {selected: number}) => {
		setCurrentPage(args.selected + 1);
	};

	return (
		<div className={classNames(WIDGETS_BAR_CLASS, styles.WidgetsBar)}>
			<div className="gates-container">
				<div ref={ref} className="gates-list">
					{openedBlock && (
						<>
							<WidgetsLaunchpad
								currentFolder={currentFolder}
								recipePoolId={props.recipePoolId}
								thingId={openedBlock.recipeId}
								thingDbId={openedBlock.DbId}
							/>
						</>
					)}
					{Object.keys(paginatedWidgets).map((widgetType, i) => (
						<WidgetButton
							key={`${widgetType}_${i}`}
							widgetInfo={paginatedWidgets[widgetType]}
							type={widgetType as WidgetType}
							onAddGate={onAddGate}
						/>
					))}
				</div>
			</div>
			<div className={styles.SearchContainer}>
				<SearchBar onFilter={onFilterByText} />
				<div className={styles.PaginationWrapper}>
					<ReactPaginate
						nextLabel={<CaretRightOutlined />}
						previousLabel={<CaretLeftOutlined />}
						onPageChange={onChangePage}
						forcePage={currentPage - 1}
						// Workaround to wrong page count bug
						pageRangeDisplayed={
							currentPage === totalPages ? 3
							: currentPage === 1 ? 3 : 2
						}
						marginPagesDisplayed={0}
						pageCount={totalPages}
						pageClassName={styles.PageItem}
						pageLinkClassName="page-link"
						previousClassName={styles.PageItem}
						previousLinkClassName="page-link"
						nextClassName={`${styles.PageItem} ${styles.Next}`}
						nextLinkClassName="page-link next"
						breakLabel={null}
						breakClassName={`${styles.PageItem} ${styles.Break}`}
						breakLinkClassName="page-link"
						containerClassName="pagination"
						activeClassName="active"
					/>
				</div>

			</div>
		</div>
	);
};

export default WidgetsBar;
