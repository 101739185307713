import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { useDrag } from 'react-dnd';
import Icon from '@ant-design/icons';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { Position } from '@kemu-io/kemu-core/types';
import { SerializableServiceInfo } from '@kemu-io/hs-types';
import Badge from 'antd/lib/badge';
import styles from './LaunchpadHubServiceIcon.module.css';
import { FolderPathInfo } from '@src/features/Workspace/workspaceSlice';
import { DroppableTypes } from '@common/constants';
import TruncatedText from '@components/truncatedText/truncatedText';
import { ReactComponent as DefaultServiceIcon } from '@src/assets/img/gates/hubService.svg';
import GateIcon from '@components/gateIcon/gateIcon';
import InjectSvg from '@components/InjectSvg/InjectSvg';
import { calculateWidgetColors } from '@components/gates/common';

interface Props {
	serviceInfo: SerializableServiceInfo;
	currentFolder: FolderPathInfo | null;
	recipePoolId: string;
	isSelected: boolean;
	disabled?: boolean;
	onClick?: (name: string, version: string) => void;
	onAddService: (info: {
		name: string,
		version: string
	}, dropLocation: Position) => void;
	onHideLaunchpad: () => void;
}

const DefaultServiceColorVar = '--kemu-color-widget-type-custom';

const LaunchpadHubServiceIcon = (props: Props): React.JSX.Element => {
	const [textExpanded, setTextExpanded] = useState(false);
	const { serviceInfo, onHideLaunchpad, onAddService } = props;
	const [iconTarget, setIconTarget] = useState<HTMLElement | null>(null);
	// const iconRef = useRef<HTMLDivElement | null>(null);

	const colors = useMemo(() => {
		const docStyle = getComputedStyle(document.body);
    const customWidgetColor = docStyle.getPropertyValue(DefaultServiceColorVar);
		return calculateWidgetColors(serviceInfo.color || customWidgetColor);
	}, [serviceInfo.color]);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [{ isDragging }, drag, preview] = useDrag<any, Position, any>(() => ({
    type: DroppableTypes.CustomWidget,
		canDrag: !props.disabled,
    item: {
			color: serviceInfo.color,
			textColor: colors.text,
			isHubService: true,
			contentColor: colors.content,
			svgIcon: serviceInfo.svgIcon,
		},
    end: (item, monitor) => {
      const dropLocation = monitor.getDropResult();
			if (dropLocation) {
				onAddService({
					name: serviceInfo.name,
					version: serviceInfo.version
				}, {
					x: dropLocation.x,
					y: dropLocation.y,
				});
			}
    },

    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }), [serviceInfo, onAddService]);


	const textVisibilityChanged = (visible: boolean) => {
		setTextExpanded(visible);
	};

	const handleClick = () => {
		props.onClick?.(serviceInfo.name, serviceInfo.version);
	};


	useEffect(() => {
		const emptyImage = getEmptyImage();
		preview(emptyImage);
		if (isDragging) {
			onHideLaunchpad();
		}
	}, [preview, isDragging, onHideLaunchpad]);

	return (
		<>
			<div
				className={
					classNames(styles.ServiceButton, {
						'text-expanded': textExpanded,
						[styles.Selected]: props.isSelected,
						'disabled': props.disabled
					})
				}
				data-kemu-meta={`hub-service-${serviceInfo.name}-${serviceInfo.version}`}
				ref={drag}
				onClick={handleClick}
			>
				<Badge
					count={'dev'}
					style={{ display: !serviceInfo.devMode ? 'none': 'block' }}
					size='small'
					offset={[-5, 0]}
					color='red'
				>
					<div
						ref={e => setIconTarget(e)}
						style={{ backgroundColor: props.disabled ? 'gray' : colors.content }}
						className={styles.Icon}
					>
						{serviceInfo.svgIcon ? (
							<InjectSvg
								contents={serviceInfo.svgIcon}
								fill={colors.text}
								targetElement={iconTarget}
							/>
						) : (
							<GateIcon
								style={{ backgroundColor: props.disabled ? 'gray' : colors.content }}
								icon={<Icon component={DefaultServiceIcon} />}
							/>
						)}
					</div>
				</Badge>

				<div className={classNames(styles.ServiceName, 'noselect')}>
					<label>
						<TruncatedText
							text={`${serviceInfo.title || serviceInfo.name}`}
							maxChars={24}
							onVisibilityChange={textVisibilityChanged}
							showFullOn="hover"
							// suffix={<div>({serviceInfo.version})</div>}
						/>
					</label>
				</div>
			</div>
		</>
	);
};

export default LaunchpadHubServiceIcon;
