import { API } from '@aws-amplify/api';
import {
	GetUserProfileResponse,
	UserPropertyValue
} from '@kemu-io/kemu-types';
import { posthog } from 'posthog-js';
import globals from '../../common/globals';
import { GetWebRTCTokenResponse } from '../../types/user';

const API_NAME = globals.USER_API_NAME;

// Forces to return the entire Axios response object instead of only response.data
const returnAxiosResponse = { response: true };

const getProfile = async (): Promise<GetUserProfileResponse> => {
	// /user/profile
	const response = await API.get(API_NAME, `/user/profile`, returnAxiosResponse);
	return response.data as GetUserProfileResponse;
};

/**
 * Fetches a token for webrtc sessions
 */
const getWebRtcSessionToken = async (): Promise<GetWebRTCTokenResponse> => {
	const response = await API.post(API_NAME, `/user/video/token`, returnAxiosResponse);
	return response.data as GetWebRTCTokenResponse;
};

/**
 * Generates a posthog capture event, updating the given property in the user profile. 
 * @param eventName the name of the event to capture
 * @param property the property name to update in the user profile
 * @param value the value to set for the property
 */
const trackEvent = (eventName: string, properties: Record<string, UserPropertyValue>): void => {
	posthog.capture(eventName, {
		$set: {
			...properties,
		}
	});
};

// const setPostHogProperties = async (properties: Record<string, UserPropertyValue> ): Promise<void> => {

// }

/**
 * Uses posthog
 */
// const setIntegrationProperties = async (integration: UserIntegrationType, properties: Record<string, UserPropertyValue> ): Promise<void> => {
// 	// FIXME: Implement this
// 	// const response = await API.get(API_NAME, `/user/properties/${group}`, { ...returnAxiosResponse, } );	
// 	// localStorage.setItem(`${group}`, JSON.stringify(properties));

// };

// const getUserProperties = async (group: UserPropertyGroup): Promise<Record<string, UserPropertyValue>> => {
// 	const item = localStorage.getItem(`${group}`);
// 	if (item) {
// 		return JSON.parse(item);
// 	}
// 	return {};
// };

export {
	getProfile,
	getWebRtcSessionToken,
	trackEvent,
};
