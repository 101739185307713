import React, { useCallback, useEffect, useRef, useMemo } from 'react';
import './LogicMapper.css';
import { RecipeGate, WidgetType } from '@kemu-io/kemu-core/types';
import { BrowserJsPlumbInstance } from '@jsplumb/community/types/dom/browser-jsplumb-instance.js';
import { Connection, ConnectionEstablishedParams, ConnectionMovedParams } from '@jsplumb/community/types/core';
import { useSelector, useDispatch } from 'react-redux';
import { removePrivateProperties , createWidgetPortIdentifier, decodeWidgetPortIdentifier } from '@kemu-io/kemu-core/common/utils';
import { FolderPathInfo, selectCurrentRecipeType, selectVisibleGroup } from '../Workspace/workspaceSlice';
import { selectLogicMapperSettings } from '../interface/interfaceSlice';
import {
	selectCurrentWidgets,
	connectGates as addChildGate,
	disconnectGate as removeChildGate,
	setGatePosition,
} from './logicMapperSlice.ts';
import WidgetPortInfoPanel from './WidgetPortInfoPanel/WidgetPortInfoPanel';
import { getGateCanvasInfo, isGateAChildAtPort } from '@src/app/recipe/utils';
import {
	StatelessWidgetsMap,
	StatelessRecipeWidget,
	JsPlumbBeforeDropEvent,
	PlumbDragEvent,
	KemuConnectionData
} from '@src/types/core_t.ts';
import CanvasGateWrapper from '@components/canvasGate/canvasGateWrapper';
import { decodeDomId, generateDomId } from '@common/utils';
import Logger from '@common/logger';
import { childWidgetLink } from '@common/hooks/useAlwaysLinked';
import { LOGIC_MAPPER_CANVAS_CLASS } from '@common/constants';
import VirtualPortManager, { VirtualPortsRef } from '@components/virtualPort/virtualPortManager';
import useDragSelection from '@common/hooks/useDragSelection';


const logger = Logger('logicMapper');
interface Props {
	plumbInstance: BrowserJsPlumbInstance;
	/** the id of the block in the recipe */
	thingRecipeId: string;
	/** id of the thing in the database */
	thingDbId: string;
	/** thing's version number */
	thingVersion: string;
	/** the id of the recipe in the pool */
	recipeId: string;
	containerEl: HTMLDivElement | null;
	onMounted?: () => void;
}


const connectGates = (groupInfo: FolderPathInfo | null, plumbInstance: BrowserJsPlumbInstance, gates: Record<string, StatelessRecipeWidget>) => {
	logger.log('Connecting widgets....');
	plumbInstance.batch(() => {
		for (const gateId in gates) {
			if (!groupInfo || groupInfo.groupId === gates[gateId].groupId) {
				const gate = gates[gateId];
				gate.children.forEach(child => {
					// Update Jul/17: With the introduction of widgetGroups and virtual ports,
					// it is possible that the child is valid but it is not currently visible. Attempting
					// to link to an invisible child will cause an error. Now we double check to make sure
					// the child exists as part of the gates map.
					if (gates[child.childId]) {
						childWidgetLink(gate.id, child, plumbInstance);
					}
				});
			}
		}
	});
};


const disconnectGates = (groupInfo: FolderPathInfo | null, recipeId: string, blockId: string, plumbInstance: BrowserJsPlumbInstance, gates: Record<string, StatelessRecipeWidget>) => {
	logger.log('Disconnecting widgets..');
	plumbInstance.batch(() => {
		for (const gateId in gates) {
			if (!groupInfo || groupInfo.groupId === gates[gateId].groupId) {
				// NOTE: Gates DOM elements use blockId_gateId format as their DOM id. Check canvasGate.tsx
				const canvasId = generateDomId(recipeId, blockId, gateId);
				plumbInstance.deleteConnectionsForElement(canvasId);
				// Delete reference in memory to the element. Check comment on virtualBlocks.tsx -> disconnectBlocks()
				delete plumbInstance.getManagedElements()[canvasId];
				// plumbInstance.unmanage(canvasId);
			}
		}
	});
};


/** 
 * SUPER important equality function to prevent re-rendering
 * every time a gate connection changes.
 * It extracts the gate's 'children' property that changes when a new connection is made/removed
 * the remaining object and compares the resulting strings
 **/
const ignoreCanvasUpdates = (current: StatelessWidgetsMap, prev: StatelessWidgetsMap) => {
	const reduceFun = (instance: StatelessWidgetsMap, map: RecipeGate, gateId: string) => {
		// NOTE: We are currently NOT ignoring changes to the 'children' property; this means
		// every time a new connection is made, ALL the widgets will be re-rendered.
		// The only way to avoid this is to read the children property directly from the recipe
		// pool in the 'connectGates' and 'disconnectGates'. However, I decided to add
		// a React.memo in the `canvasGateWrapper` instead, to prevent re-rendering of the canvas element itself.
		// Although the wrapper will be re-rendered on every connection, its computation cost is low enough to 
		// allow us to get away with it for now. If that changes, consider implementing the above mentioned alternative.


		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { canvas, ...rest } = instance[gateId];
		const cleanedProps = removePrivateProperties(rest);
		return { ...map, [gateId]: { ...cleanedProps } };
	};

	const prevFiltered = Object.keys(prev).reduce(reduceFun.bind(null, prev), {} as RecipeGate);
	const currentFiltered = Object.keys(current).reduce(reduceFun.bind(null, current), {} as RecipeGate);
	const areEqual = JSON.stringify(prevFiltered) === JSON.stringify(currentFiltered);
	return areEqual;
};

type SourceInfo = {
	id: string;
	type: string;
}

/**
 * Adds a class to the canvas container
 * NOTE: This is currently a workaround to avoid re-rendering all the canvas and children
 * elements. We manipulate the container directly using vanilla (ugh...)
 */
 const setCanvasContainerClass = (sources: SourceInfo[], remove=false) => {
	const canvasContainer = document.querySelector(`.${LOGIC_MAPPER_CANVAS_CLASS}`);
	if (canvasContainer) {
		sources.forEach(source => {
			if (!remove) {
				canvasContainer.classList.add(source.type);
				canvasContainer.setAttribute('data-drag-source', source.id);
			} else {
				canvasContainer.classList.remove(source.type);
				canvasContainer.removeAttribute('data-drag-source');
			}
		});
	}
};

/**
 * Returns the origin endpoint types from the event
 */
const getSourceEndpointNames = (conn: Connection): null | SourceInfo[] => {
	if (conn?.endpoints && conn?.endpoints.length) {
		const classes = conn.endpoints[0].cssClass.split(' ');
		const types = classes.filter(className => className.startsWith('type-'));
		// Always return the last type, since it is the most specific one
		return types.length ? [{
			type: types[types.length - 1],
			id: conn.endpoints[0].elementId
		}] : null;
		// return types.map(type => ({
		// 	type,
		// 	id: conn.endpoints[0].elementId
		// }));
		// return types.length ? {
		// 	type,
		// 	id: conn.endpoints[0].elementId
		// } : null;
	}

	return null;
};


const LogicMapper = (props: Props): React.JSX.Element => {
	const dispatch = useDispatch();
	const visibleGroup = useSelector(selectVisibleGroup);
	const widgets = useSelector(selectCurrentWidgets, ignoreCanvasUpdates);
	const logicMapperSettings = useSelector(selectLogicMapperSettings);
	const currentRecipeType = useSelector(selectCurrentRecipeType);
	const virtualPortsRef = useRef<VirtualPortsRef | null>(null);
	// Kind of an ugly workaround to force the logic mapper to re-build connections
	// after widgets have been pasted
	// const forceReRender = useSelector(selectRenderCounter);
	// A map of widgets that have already painted their ports
	const paintedPortsRef = useRef<{visibleGroup: FolderPathInfo | null, widgets: Record<string, boolean>}>({ widgets: {}, visibleGroup });
	// const { jspInstance: plumbInstance } = usePlumb();
	// if(!plumbInstance) { throw new Error(`Plumb library has not be initialized`); }

	const { recipeId, thingRecipeId, thingDbId, thingVersion, onMounted, plumbInstance } = props;
	useDragSelection(props.containerEl, plumbInstance, visibleGroup?.groupId);
	const gatesRef = useRef<StatelessWidgetsMap>({});

	// Contains a list of the visible gates
	const gatesToShow: StatelessWidgetsMap = useMemo(() => {
		const map: StatelessWidgetsMap = {};
		paintedPortsRef.current = {
			widgets: {},
			visibleGroup,
		};

		for (const gateId in widgets) {
			if (visibleGroup && visibleGroup.groupId === widgets[gateId].groupId) {
				// Show only widgets inside the current group
				map[gateId] = { ...widgets[gateId] };
				paintedPortsRef.current.widgets[gateId] = false;
			} else if (!visibleGroup && !widgets[gateId].groupId) {
				// Show only widgets without a group
				map[gateId] = { ...widgets[gateId] };
				paintedPortsRef.current.widgets[gateId] = false;
			}

			// Build a map 
		}

		// Keep reference updated every time it changes.
		// gatesRef.current = map;
		return map;
	}, [widgets, visibleGroup]);



/**
 * Keeps track of which ports have been painted and triggers
 * a re-render of the connections once they are all painted.
 */
// const handlePortsPainted = useCallback((widgetId: string) => {
// 	paintedPortsRef.current.widgets[widgetId] = true;
// 	const allPortsPainted = Object.values(paintedPortsRef.current.widgets).every((value) => value);
// 	if (allPortsPainted) {
// 		logger.log('All ports painted, re-drawing connections');
// 		// IMPORTANT: Always use the latest recipe state to draw connections as we can't rely
// 		// on the local redux store for this. The store is only updated during certain UI events.
// 		const thing = findThingInRecipe(recipeId, thingRecipeId);
// 		Object.keys(gatesRef.current).forEach((widgetId) => {
// 			// Update the children property of the widget
// 			gatesRef.current[widgetId].children = [...thing?.gates[widgetId].children || []];
// 		});

// 		// Paint child connections
// 		connectGates(paintedPortsRef.current.visibleGroup, plumbInstance, gatesRef.current);
// 		// Paint virtual ports
// 		virtualPortsRef.current?.paintGroupConnections();
// 		return true;
// 	} else {
// 		return false;
// 	}
// }, [plumbInstance, recipeId, thingRecipeId]);


	// Decodes the event and dispatches the action
	const addOrRemoveChild = useCallback((action: 'add' | 'remove', event: ConnectionEstablishedParams | ConnectionMovedParams) => {

		const isMovedEvent = !!(event as ConnectionMovedParams).originalSourceId && !!(event as ConnectionMovedParams).originalTargetId;

		const sourceId = (event as ConnectionMovedParams).originalSourceId || (event as ConnectionEstablishedParams).sourceId;
		const targetId = (event as ConnectionMovedParams).originalTargetId || (event as ConnectionEstablishedParams).targetId;

		let sourceEndpointId = isMovedEvent ? (event as ConnectionMovedParams).originalSourceEndpoint.getUuid() : (event as ConnectionEstablishedParams).sourceEndpoint.getUuid();
		let targetEndpointId = isMovedEvent ? (event as ConnectionMovedParams).originalTargetEndpoint.getUuid() : (event as ConnectionEstablishedParams).targetEndpoint.getUuid();

		// Now, remove the gateId which is not relevant
		const decodedSource = decodeWidgetPortIdentifier(sourceEndpointId);
		const decodedTarget = decodeWidgetPortIdentifier(targetEndpointId);

		sourceEndpointId = createWidgetPortIdentifier(decodedSource.widgetId, decodedSource.portType, decodedSource.portName);
		targetEndpointId = createWidgetPortIdentifier(decodedTarget.widgetId, decodedTarget.portType, decodedTarget.portName);

		const sourceGate = decodeDomId(sourceId);
		const targetGate = decodeDomId(targetId);

		if (!sourceGate || !targetGate) { return; }

		const executeFun = action === 'add' ? addChildGate : removeChildGate;


		dispatch(executeFun({
			recipeId: recipeId,
			blockRecipeId: thingRecipeId,
			sourceGateId: sourceGate.gateId!,
			targetGateId: targetGate.gateId!,
			sourcePortId: sourceEndpointId,
			targetPortId: targetEndpointId
		}));
	}, [dispatch, thingRecipeId, recipeId]);


	/** removes the type-N class added to the canvas container */
	const removeContainerClass = useCallback((conn: Connection) => {
		// Remove container reference
		const types = getSourceEndpointNames(conn);
		if (types) {
			setCanvasContainerClass(types, true);
		}
	}, []);

	/** Invoked when 2 gates are linked */
	const handleConnectionEvent = useCallback((event: ConnectionEstablishedParams, mouseEvent: MouseEvent) => {
		const manuallyAdded = mouseEvent !== undefined;
		const sourceGate = decodeDomId(event.sourceId);
		if (sourceGate?.gateId) {
			const data: KemuConnectionData = {
				sourceWidgetId: sourceGate.gateId,
				sourcePortId: event.sourceEndpoint.getUuid(),
				targetPortId: event.targetEndpoint.getUuid()
			};

			event.connection.setData(data);
		}

		// Ignore any programmatic links
		if (manuallyAdded) {
			addOrRemoveChild('add', event);
			removeContainerClass(event.connection);
		}
	}, [addOrRemoveChild, removeContainerClass]);

	/** Invoked when 2 gates are unlinked */
	const handleConnectionDetachedEvent = useCallback((event: ConnectionEstablishedParams, mouseEvent: MouseEvent) => {
		const manuallyRemoved = mouseEvent !== undefined;
		if (manuallyRemoved) {
			addOrRemoveChild('remove', event);
			removeContainerClass(event.connection);
		}
	}, [addOrRemoveChild, removeContainerClass]);

	/** Invoked when a connections is moved from one gate to another */
	const handleConnectionMovedEvent = useCallback((event: ConnectionMovedParams, mouseEvent: MouseEvent) => {
		const sourceBlock = decodeDomId(event.originalSourceId);
		const targetBlock = decodeDomId(event.originalTargetId);
		if (!sourceBlock || !targetBlock) { return; }
		logger.log(`Widget connection moved event from ${sourceBlock.blockId} to ${targetBlock.blockId} by ${mouseEvent ? 'user' : 'system'}`);
		addOrRemoveChild('remove', event);
	}, [addOrRemoveChild]);

	/** 
	 * Updates the block's position in the recipe
	 **/
	const handleGateMovedEvent = useCallback((evt: PlumbDragEvent) => {
		const gate = decodeDomId(evt.el.id);
		// Update Jul/17: Ignore virtual ports (which up until now are the only ones using uid)
		// since dragging them around should NOT affect the position of the actual widget.
		if (gate && !gate.uid) {
			const gateId = gate.gateId!;
			const canvasInfo = getGateCanvasInfo(recipeId, thingRecipeId, gateId);
			canvasInfo.position = {
				x: evt.pos.left,
				y: evt.pos.top
			};

			dispatch(setGatePosition({
				canvasInfo: canvasInfo,
				sourceGateId: gateId,
				blockRecipeId: thingRecipeId,
				recipeId
			}));
		}
	}, [recipeId, thingRecipeId, dispatch]);


	/** Triggered when a new connection is being made */
	const handleConnectionDragEvent = useCallback((connEvt: Connection) => {
		const types = getSourceEndpointNames(connEvt);
		if (types) {
			setCanvasContainerClass(types);
		}
	}, []);

	/** New connection was aborted */
	const connectionAborted = useCallback((connEvt: Connection) => {
		const types = getSourceEndpointNames(connEvt);
		if (types) {
			setCanvasContainerClass(types, true);
		}
	}, []);

	/** triggered when the user double clicks on a connection */
	const handleConnectionDblClick = useCallback((connEvt: Connection, originalEvent: Event) => {
		// Note: this triggers the handleConnectionDetachedEvent. We pass the original event so
		// it gets recognized as a manual event and the relationship removed from the recipe
		plumbInstance.deleteConnection(connEvt, { fireEvent: true, originalEvent });
	}, [plumbInstance]);

	/** Called when a connection is about to be dropped */
	const handleBeforeDrop = useCallback((event: JsPlumbBeforeDropEvent) => {
		// Check if the target is not already a children
		const sourceGate = decodeDomId(event.sourceId);
		const targetGate = decodeDomId(event.targetId);
		if (!sourceGate || !targetGate || !targetGate.gateId || !sourceGate.gateId) { return; }
		const sourceEp = event.connection.endpoints[0];

		// NOTE: Because the useSelector that extracts the current gates from the store
		// is removing the 'children' property in order to avoid re-renderings, we don't actually
		// have an up-to-date state of the gates (in the `gates` variable declared at the beginning of the component).
		// So, we use the utility module to access the recipe cache directly and check if the gate is already a child.
		const alreadyAChild = isGateAChildAtPort(
			sourceGate.recipeId,
			sourceGate.blockId,
			sourceGate.gateId,
			targetGate.gateId,
			sourceEp.getUuid(),
			// Why using `event.dropEndpoint.getUuid`?
			// From the official documentation: https://docs.jsplumbtoolkit.com/community/current/articles/events-community.html#evt-beforedrop
			// "You can access the 'endpoints' array in a Connection to get the Endpoints involved in the Connection, 
			// but be aware that when a Connection is being dragged, one of these Endpoints will always be a transient 
			// Endpoint that exists only for the life of the drag. To get the Endpoint on which the Connection is being 
			// dropped, use the dropEndpoint member"
			event.dropEndpoint.getUuid()
		);

		if (alreadyAChild) {
			logger.log('Ignoring connection request, child widget and port already exist');
			return false;
		}

		return true;
	}, []);


	// IMPORTANT: Order of these `useCallbacks` and `useEffects` matters!
	// 1) Remove event listeners MUST happen BEFORE we update the gatesRef, otherwise
	// the reference inside removeEventListeners will be too new and old connections won't be removed.
	const removeEventListeners = useCallback(() => {
		logger.log('Removing event listeners');
		plumbInstance.unbind('connectionDrag', handleConnectionDragEvent);
		plumbInstance.unbind('connectionAborted', connectionAborted);
		plumbInstance.unbind('connection', handleConnectionEvent);
		plumbInstance.unbind('connectionDetached', handleConnectionDetachedEvent);
		plumbInstance.unbind('connectionMoved', handleConnectionMovedEvent);
		plumbInstance.unbind('beforeDrop', handleBeforeDrop);
		plumbInstance.unbind('drag:stop', handleGateMovedEvent);
		plumbInstance.unbind('dblclick', handleConnectionDblClick);
		disconnectGates(visibleGroup, recipeId, thingRecipeId, plumbInstance, gatesRef.current);
	}, [
		plumbInstance, recipeId, thingRecipeId, visibleGroup,
		handleBeforeDrop, handleConnectionMovedEvent, handleConnectionEvent,
		handleGateMovedEvent, handleConnectionDetachedEvent, handleConnectionDragEvent,
		connectionAborted, handleConnectionDblClick
	]);

	// 2) We need to keep the reference updated so that `setupListeners` and `removeEventListeners` use the
	// latest version, since useMountEffect will call them with a stale version of 'gates'
	useEffect(() => {
		gatesRef.current = gatesToShow;
	}, [gatesToShow, recipeId, thingRecipeId]);


	// 3) We need to define this AFTER the gatesRef has been updated, so that
	// when building the connections, gatesRef refers to the newest copy
	const setupListeners = useCallback(() => {
		logger.log('Creating event listeners');
		plumbInstance.bind('connectionDrag', handleConnectionDragEvent);
		plumbInstance.bind('connectionAborted', connectionAborted);
		plumbInstance.bind('connection', handleConnectionEvent);
		plumbInstance.bind('connectionDetached', handleConnectionDetachedEvent);
		plumbInstance.bind('connectionMoved', handleConnectionMovedEvent);
		plumbInstance.bind('beforeDrop', handleBeforeDrop);
		plumbInstance.bind('drag:stop', handleGateMovedEvent);
		plumbInstance.bind('dblclick', handleConnectionDblClick);
		onMounted?.();
	}, [
		plumbInstance, /* visibleGroup, */ handleBeforeDrop,
		handleConnectionMovedEvent, handleConnectionEvent,
		handleGateMovedEvent, handleConnectionDetachedEvent,
		handleConnectionDragEvent, connectionAborted,
		handleConnectionDblClick, onMounted
	]);


	// Force repainting everything if the group changes
	useEffect(() => {
		setupListeners();
		return () => {
			removeEventListeners();
		};
	}, [
		visibleGroup,
		setupListeners,
		removeEventListeners,
		// forceReRender,
	]);

	return (
		// NOTE: Draggable objects can NOT be contained, they must be immediate children of the 
		// jsplumb draggable container, currently set to 'canvas-background' (CANVAS_DRAGGABLE_CLASS). Check canvas.tsx for more info
		<>
			{logicMapperSettings.debugPanelVisible && (
				<WidgetPortInfoPanel plumbInstance={plumbInstance} />
			)}

			{/* {Object.keys(gatesToShow).map((gateName) => { */}
			{Object.keys(widgets).map((widgetId) => {
				const widget = gatesToShow[widgetId];
				const visible = !!widget;
				// Always render hub services even if they are not visible
				if (!visible && widgets[widgetId].type !== WidgetType.hubService) { return; }

				return (
					<CanvasGateWrapper
						// onPortsPainted={handlePortsPainted}
						hidden={!visible}
						gateId={widgets[widgetId].id}
						key={widgets[widgetId].id}
						thingRecipeId={thingRecipeId}
						thingDbId={thingDbId}
						thingVersion={thingVersion}
						recipeId={recipeId}
						plumbInstance={plumbInstance}
					/>
				);
			})}


			{!!visibleGroup && (
				<VirtualPortManager
					recipeId={recipeId}
					blockId={thingRecipeId}
					groupId={visibleGroup.groupId}
					recipeType={currentRecipeType}
					plumbInstance={plumbInstance}
					ref={virtualPortsRef}
				/>
			)}
		</>
	);
};

export default LogicMapper;
