import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import Kemu from '@kemu-io/kemu-core';
import { ThingMap, CachedRecipeDbInfo, Recipe } from '@kemu-io/kemu-core/types';
import { findRecipe } from '@kemu-io/kemu-core/common/recipeCache';
// import { migrateRecipeTov1_6 } from './helpers';
import { DEFAULT_THING_ID, DEFAULT_THING_VERSION } from '@kemu-io/kemu-core/common/constants';
import * as RecipeActions from '@src/app/recipe/utils';
import { openBlockAction, setRecipeMetaAction, terminateRecipeAction, WorkspaceState } from '@src/features/Workspace/workspaceSlice';
import { showGlobalNotification } from '@src/features/interface/interfaceSlice';
import { AsyncRequestStatus } from '@src/types/core_t';
import { RootState } from '@src/app/store';
import connectionManager from '@src/app/kemuHub/connectionManager';

export interface MinimumRecipeInfo {
	blocks: ThingMap;
	isDefault: boolean;
	hasErrors: boolean,
	name: string
}

type OpenRecipeActionResult = MinimumRecipeInfo & {
	poolId: string;
}

type OpenRecipeOptions = {
	recipe: Recipe,
	recipeName?: string,
	dbInfo: CachedRecipeDbInfo,
	isDefault: boolean,
}

export const openRecipeAction = createAsyncThunk<OpenRecipeActionResult, OpenRecipeOptions>('/Workspace/openRecipe', async (options, thunkAPI) => {

	const { storage: originalStorage, ...storageLessRecipe } = options.recipe;

	const { workspace } = thunkAPI.getState() as RootState;
	// Terminate the current recipe
	await thunkAPI.dispatch(terminateRecipeAction({
		recipePoolId: workspace.currentRecipe.poolId as string || '',
	}));

	// Will contain a reference to the old storage keys without the id marker
	// {#}STORAGE_KEY: STORAGE_KEY
	const dataLessStorageKeys: Record<string, string> = {};

	if (originalStorage) {
		// keep track of the original storage keys and their ids. We will need this to match the re-generated ids
		Object.keys(originalStorage).forEach(id => {
			dataLessStorageKeys[id] = RecipeActions.removeIdMarker(id);
		});

		// @ts-expect-error yeah, I know, `dataLessStorage` is not a valid object storage type
		storageLessRecipe.storage = dataLessStorageKeys;
	}

	// Generating new ids every time a recipe is opened allows users to open the same recipe
	// multiple times
	const regeneratedStr = RecipeActions.regenerateIds(JSON.stringify(storageLessRecipe));
	const recipe: Recipe = JSON.parse(regeneratedStr);
	const recipeProtocolVersion = Number(recipe.protocolVersion);

	// NOTE: We cannot restore the `storage` prop yet because the recipeCache.storeRecipe creates a
	// copy of the recipe before adding it to the cache. Restoring now would  cause the binary data in
	// `storage` to be serialized
	const poolId = await Kemu.registerRecipe(
		recipe,
		options.dbInfo.id,
		options.dbInfo.version,
		options.dbInfo.authorId,
		options.dbInfo.recipeType,
	);



	// Get the cached recipe instance to add the storage now
	const cachedRecipe = findRecipe(poolId);
	// Register the new recipe in the Kemu Hub connection manager
	connectionManager.setCurrentRecipePoolId(poolId);

	// If already connected, issue subscriptions for all event emitter services.
	if (connectionManager.isReady()) {
		connectionManager.reIssueWidgetsSubscriptions();
	}

	// Translate old port references to the new format.
	// if (cachedRecipe && recipeProtocolVersion && (recipeProtocolVersion < 1.6)) {
	// 	// FIXME: Migration script not currently working
	// 	cachedRecipe = migrateRecipeTov1_6(cachedRecipe, poolId);
	// }

	if (cachedRecipe && cachedRecipe.storage && originalStorage) {
		const ID_MARKER = RecipeActions.getIdMarker();
		// We can now restore the real storage units
		for (const blockId in cachedRecipe.blocks) {
			// Block units point to a newly generated Id; in the recipe.storage object,
			// the value of those properties is the previous id of the storage unit without the id marker.
			const blockUnits = cachedRecipe.blocks[blockId].storageUnits || [];
			for (const newUintId of blockUnits) {
				const oldId = `${ID_MARKER}${cachedRecipe.storage[newUintId]}`;
				const realUnitData = originalStorage[oldId];
				if (realUnitData) {
					cachedRecipe.storage[newUintId] = realUnitData;
				}
			}
		}
	}

	// Set entity information
	thunkAPI.dispatch(setRecipeMetaAction({
		id: options.dbInfo.id,
		version: options.dbInfo.version,
		name: options.recipeName || recipe.name,
		recipeType: options.dbInfo.recipeType,
		poolId,
	}));


	// Initialize interrupts
	const { failed, invokeRecipeLoadedWidgets } = await Kemu.initializeRecipe(poolId);
	if (failed.length > 0) {
		console.error('Some widgets failed to initialize: ', failed);
		thunkAPI.dispatch(showGlobalNotification({
			title: 'Initialization Error',
			message: 'Some widgets loaded with errors. This recipe may not work as expected.',
			type: 'error',
		}));
	}

	thunkAPI.dispatch(openBlockAction({
		DbId: DEFAULT_THING_ID,
		recipeId: DEFAULT_THING_ID,
		recipePoolId: poolId,
		title: '',
		version: DEFAULT_THING_VERSION,
	}));

	// Trigger all `recipeLoad` widgets
	await invokeRecipeLoadedWidgets().catch(err => {
		console.error('Error invoking recipe loaded widgets: ', err);
	});

	return {
		isDefault: options.isDefault,
		blocks: recipe.blocks,
		name: recipe.name,
		hasErrors: failed.length > 0,
		poolId,
	};
});


export const openRecipeReducer = ((builder: ActionReducerMapBuilder<WorkspaceState>): void => {
  builder.addCase(openRecipeAction.fulfilled, (state, action: PayloadAction<OpenRecipeActionResult>) => {
    state.currentRecipe.blocks.collection = action.payload.blocks;
    state.currentRecipe.blocks.status = AsyncRequestStatus.completed;
    state.currentRecipe.isDefault = action.payload.isDefault;
    state.currentRecipe.name = action.payload.name;
    state.openingRecipe = false;
		// Mark default thing as current
		// state.selectedBlockInfo = {
		// 	// DbId: DEFAULT_THING_ID,
		// 	// recipeId: DEFAULT_THING_ID,
		// 	// recipePoolId: action.payload.poolId,
		// 	// title: '',
		// 	// version: DEFAULT_THING_VERSION,
		// };

		state.currentRecipe.openedWithErrors = action.payload.hasErrors;
  });

  builder.addCase(openRecipeAction.rejected, (state, action) => {
    console.log('Error opening recipe: ', action);
    state.openingRecipe = false;
  });

  builder.addCase(openRecipeAction.pending, (state) => {
    state.openingRecipe = true;
  });
});
