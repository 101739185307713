import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { currentRecipePoolId, unsavedChanges } from '@src/features/Workspace/workspaceSlice';


/**
 * Hook to prevent navigation away from the page when the page is unloaded.
 * If the recipe has unsaved changes, it will show a confirmation dialog.
 */
const usePreventNavigation = () => {
  // const recipePoolId = useSelector(currentRecipePoolId);
  const needsSaving = useSelector(unsavedChanges);

  useEffect(() => {
    const onConfirmRefresh = (event: BeforeUnloadEvent) => {
      console.log('Validating unsaved changes');
       // Cancel the event as stated by the standard.
       event.preventDefault();
       // Chrome requires returnValue to be set.
       event.returnValue = '';
       return '';
    };

    // Prevent the browser from navigating back to the previous page
    // FIXME: not currently working
    const handlePopState = (event: PopStateEvent) => {
      // event.preventDefault();
      // history.pushState(null, document.title, location.href);
    };

    if (needsSaving) {
      console.log('Adding beforeunload listener');
      // "It is therefore recommended that developers listen for beforeunload only
      // when users have unsaved changes so that the dialog mentioned above can be used 
      // to warn them about impending data loss, and remove the listener again when it is not needed. 
      // Listening for beforeunload sparingly can minimize the effect on performance."
      // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
      window.addEventListener('beforeunload', onConfirmRefresh);
      window.addEventListener('popstate', handlePopState);
    }

    return () => {
      console.log('Removing beforeunload listener');
      window.removeEventListener('beforeunload', onConfirmRefresh);
      window.removeEventListener('popstate', handlePopState);
    };
  }, [needsSaving]);
};

export default usePreventNavigation;
