import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Select, Input } from 'antd';
import StyledInput from '../../form-control/styledInput/styledInput';
import FormGroup from '../../form-control/formGroup/formGroup';
import useMarkedText from '../../../common/hooks/useMarkedText';
import KemuSwitch from '../../form-control/kemuSwitch/KemuSwitch';
import useTranslation from '../../../common/hooks/useTranslation';
import styles from './publisher.module.css';

interface Props {
	onTitleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onSubtitleChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
	onAgeSelected?: (age: string) => void;
	onCategoriesChanged: (list: string[]) => void;
	onDescriptionChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
	categories: string[];
	title: string;
	subtitle?: string;
	age?: number;
	description: string;
}

const { Option } = Select;
const defaultAges = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];
const defaultCategories = ['Science', 'Math', 'Ai', 'Machine Learning', 'Information technology', 'Security', 'Gaming', 'Education'];


const RecipeDetails = (props: Props): React.JSX.Element => {
	const convertToHtml = useMarkedText();
	const [showPreview, setShowPreview] = useState(false);
	const t = useTranslation('Marketplace.MyRecipes.PublishWizard');
	const selectedAge = props.age ? `${props.age}-${Number(props.age)+1}` : '';
	const onAgeChanged = (value: string) => {
		props.onAgeSelected?.(value);
	};

	const getParsedDescription = () => {
		return convertToHtml(props.description);
	};

	const handleCategoryChange = (values: string[]) => {
		props.onCategoriesChanged(values);
	};


	return (
		<div>
			<FormGroup>
				<StyledInput onChange={props.onTitleChange} value={props.title} name='title' label={<FormattedMessage id="Marketplace.MyRecipes.PublishWizard.MainTitle" defaultMessage="Main Title" />}/>
			</FormGroup>

			<FormGroup>
				<label>
					{t('SubTitle', 'Subtitle (Maximum number of letters is 100)')}
				</label>
				<Input.TextArea showCount maxLength={100} className={styles.TextArea} name="subtitle" value={props.subtitle} onChange={props.onSubtitleChange} />
			</FormGroup>

			<FormGroup row={true}>
				<FormGroup column={true} className={styles.DropdownSection}>
					<label>
						{t('RecommendedAge', 'Recommended Age')}
					</label>
					<Select className={styles.Select} defaultValue={selectedAge} onChange={onAgeChanged}>
						<Option value="" key="default"> </Option>
						{defaultAges.map((age, index) => (
							<Option value={age} key={index}>{age}-{age+1}</Option>
						))}
					</Select>
				</FormGroup>



				<FormGroup column={true} className={styles.CategorySelect}>
					<label>
						{t('Category', 'Categories')}
					</label>

					<Select mode="tags" defaultValue={props.categories} className={styles.Select} placeholder="Enter keyword" onChange={handleCategoryChange}>
						{props.categories.map((category, index) => (
							<Option value={category} key={index}>{category}</Option>
						))}

						{defaultCategories.filter(c => !props.categories.includes(c)).map((category, index) => (
							<Option value={category} key={`d-${index}`}>{category}</Option>
						))}
					</Select>
				</FormGroup>
			</FormGroup>

			<FormGroup column={true}>
				<div className={styles.DescriptionHeader}>
					<label>
						{t('Description', 'Detailed Description')}
					</label>
					<div className={styles.PreviewSwitchContainer}>
						<label>{t('PreviewLabel', 'Preview')}</label>
						<KemuSwitch  checked={showPreview} onChange={() => setShowPreview(p => !p)} size="small" />
					</div>
				</div>

				{showPreview ? (
					<div className={styles.PreviewViewer} dangerouslySetInnerHTML={{ __html: getParsedDescription() }} />
				) : (
					<Input.TextArea
						name="description"
						value={props.description}
						onChange={props.onDescriptionChange}
						className={`${styles.TextArea} ${styles.Description}`}
						showCount
						maxLength={1500}
					/>
				)}
			</FormGroup>
		</div>
	);
};

export default RecipeDetails;
