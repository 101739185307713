import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BrowserJsPlumbInstance } from '@jsplumb/community';
import { Endpoint } from '@jsplumb/community/types/core';
import { Inspector } from 'react-inspector';
import { eventHistory } from '@kemu-io/kemu-core/common';
import { Resizable, ResizeCallbackData } from 'react-resizable';
import classNames from 'classnames';
import { CaretRightFilled, CaretDownFilled } from '@ant-design/icons';
import { DataType, SupportedTypes, Data } from '@kemu-io/hs-types';
import styles from './WidgetPortInfoPanel.module.css';
import { customTheme } from './inspectorTheme';
import { decodeDomId, portTypeToString } from '@common/utils';
import { KemuEndpointData } from '@src/types/core_t';
import useTranslation from '@hooks/useTranslation';
import { CANVAS_DRAGGABLE_CLASS } from '@common/constants';

type Props = {
  plumbInstance: BrowserJsPlumbInstance;
}

type ElementWithJTKEndpoint = HTMLElement & {
  jtk?: {
    endpoint: Endpoint;
  }
}

const minContractedPanelSize = { width: 166, height: 56 };
const minExpandedPanelSize = { width: 166, height: 200 };
const PanelOffset = 30;
const EndpointHeight = 14;
const ignoreObjectTypes = [DataType.ImageData, DataType.AudioBuffer, DataType.ArrayBuffer, DataType.ImageBitmap];
type PortInfo = {
  isOutput: boolean;
  portType: DataType | DataType[];
  lastTimestamp?: number;
  lastDetectedEventType?: DataType;
  lastDetectedValue?: string | number | boolean | Record<string, unknown>;
  lastValueIsObject?: boolean;
}

/**
 * Loops through all the properties of an object and removes any that are not serializable
 * such as ArrayBuffer, ImageBitmap, etc.
 * @param obj 
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const removeArrayBufferProps = (obj: any): SupportedTypes => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => removeArrayBufferProps(item)) as SupportedTypes;
  }

  const finalObj: Record<string, unknown> = {};
  const objKeys = Object.keys(obj);

  objKeys.forEach((key) => {
    const value = obj[key];
    if (value instanceof ArrayBuffer) {
      finalObj[key] = '<ArrayBuffer>';
    } else if (value instanceof ImageData) {
      finalObj[key] = '<ImageData>';
    } else if (value instanceof AudioBuffer) {
      finalObj[key] = '<AudioBuffer>';
    } else if (Array.isArray(value)) {
      finalObj[key] = value.map((item) => removeArrayBufferProps(item));
    } else if (typeof value === 'object') {
      finalObj[key] = removeArrayBufferProps(value as Record<string, unknown>);
    } else {
      finalObj[key] = value;
    }
  });

  return finalObj as SupportedTypes;
};

const WidgetPortInfoPanel = (props: Props) => {
  const { plumbInstance } = props;
  const [panelVisible, setPanelVisible] = useState(false);
  const canvasDraggableRef = useRef<HTMLDivElement | null>(null);
  const [portInfo, setPortInfo] = useState<PortInfo | null>(null);
  const [expandObjectDetails, setExpandObjectDetails] = useState(false);
  const [panelSize, setPanelSize] = useState<{ width: number, height: number }>({ ...minContractedPanelSize });
  const hideTimerRef = useRef<NodeJS.Timeout | null>(null);
  const panelElRef = useRef<HTMLDivElement>(null);
  const panelVisibleRef = useRef<boolean>(false);
  const lastHoveredPortRef = useRef<Endpoint | null>(null);

  const lastPortLocationRef = useRef<{
    left: number,
    top: number,
    widgetLeft: number,
  } | null>(null);
  const t = useTranslation();
  const wpT = useTranslation('LogicMapper.WidgetPortDebugPanel');
  const portTypes = Array.isArray(portInfo?.portType) ? portInfo?.portType : [portInfo?.portType];
  const portTypeNames = portTypes?.map((type) => portTypeToString(t, type as DataType));
  const showDetailsPanel = portInfo?.lastValueIsObject && expandObjectDetails;

  const togglePanelExpansion = () => {
    const nextExpanded = !expandObjectDetails;
    if (nextExpanded) {
      // Make sure the expanded dimensions are large enough
      const nextW = panelSize.height < minExpandedPanelSize.height ? minExpandedPanelSize.height : panelSize.height;
      const nextH = panelSize.width < minExpandedPanelSize.width ? minExpandedPanelSize.width : panelSize.width;
      setPanelSize({ width: nextW, height: nextH });
    } else {
      setPanelSize({ width: minContractedPanelSize.width, height: minContractedPanelSize.height });
    }

    setExpandObjectDetails(!expandObjectDetails);
  };

  const handleResize = useCallback((_event: React.SyntheticEvent<Element, Event> & { srcElement?: HTMLElement}, data: ResizeCallbackData) => {
		const newWidth = data.size.width;
		const newHeight = data.size.height;
    if (showDetailsPanel) {
      setPanelSize({ width: newWidth, height: newHeight });
    }
  }, [showDetailsPanel]);

  const makePanelInvisible = useCallback(() => {
    setPanelVisible(false);
    hideTimerRef.current = null;
  }, []);

  useEffect(() => {
    // Monitor mouse movement to show/hide the panel when over an element with the class `source-port`
    const mouseMoveHandler = (e: MouseEvent) => {
      // NOTE: jsPlumb adds a `jtk` property to every canvas element that contains endpoints (ports)
      const target = e.target as ElementWithJTKEndpoint;
      const isSourcePort = target.classList.contains('source-port');
      const isTargetPort = target.classList.contains('target-port');
      const panelEl = panelElRef.current;
      // Check if the mouse is over the panel
      const targetIsPanel = panelEl?.contains(target);
      const portDraggingInProgress = canvasDraggableRef.current?.getAttribute('data-drag-source');

      // Hide panel immediately when dragging
      if (portDraggingInProgress) {
        makePanelInvisible();
        return;
      }

      // Keep the panel visible if the mouse is over it
      /* if (targetIsPanel && hideTimerRef.current) {
        clearTimeout(hideTimerRef.current);
        hideTimerRef.current = null;
        return;
      } */

      const endpointPort = target.jtk?.endpoint;
      const endpointElement = endpointPort?.element as HTMLElement;
      const { left, top } = target.getBoundingClientRect();

      // const programHiding = () => {
      //   if (panelVisibleRef.current) {
      //     hideTimerRef.current && clearTimeout(hideTimerRef.current);
      //     hideTimerRef.current = setTimeout(makePanelInvisible, 300);
      //   }
      // };
      const isShiftKey = e.shiftKey;
      if (endpointPort) {
        lastHoveredPortRef.current = endpointPort;
      }

      if (!isShiftKey && !targetIsPanel) {
        makePanelInvisible();
        return;
      }

      if (!lastHoveredPortRef.current || !panelEl || !endpointElement) {
        return;
      }

      // if (!isShiftKey || !endpointElement || !panelEl || !endpoint) {
      //   // programHiding();
      //   makePanelInvisible();
      //   return;
      // }

      const endpoint = lastHoveredPortRef.current;
      const widgetInfo = decodeDomId(lastHoveredPortRef.current.elementId);
      const panelDimensions = panelEl.getBoundingClientRect();
      const widgetDimensions = endpointElement.parentElement?.getBoundingClientRect();
      lastPortLocationRef.current = { left, top, widgetLeft: widgetDimensions?.left || 0 };

      if (!widgetDimensions || portDraggingInProgress) {
        // programHiding();
        makePanelInvisible();
        return;
      }

      const portData = endpoint.getData() as KemuEndpointData;

      if (isTargetPort) {
        if (widgetInfo?.blockId && widgetInfo?.gateId && portData?.name) {
          // Position panel to the left of the port
          const panelHeight = (panelDimensions.height / 2);
          panelEl.style.left = `${left - widgetDimensions.left - panelDimensions.width - (PanelOffset / 2)}px`;
          panelEl.style.top = `${top - widgetDimensions.top + (EndpointHeight / 2) - panelHeight}px`;
          setPortInfo({
            portType: portData.type,
            isOutput: false,
          });
        }
      }

      if (isSourcePort) {
        // Position panel to the right of the port
        const panelHeight = (panelDimensions.height / 2);
        panelEl.style.left = `${left - widgetDimensions.left + PanelOffset}px`;
        panelEl.style.top = `${top - widgetDimensions.top + (EndpointHeight / 2) - panelHeight}px`;

        if (widgetInfo?.blockId && widgetInfo?.gateId && portData?.name) {
          const history = eventHistory.getLastProducedPortEvent(widgetInfo?.blockId, widgetInfo?.gateId, portData.name);
          const historyData = history?.data as Data | undefined;
          let historyEventValue = historyData?.value;
          const lastValueIsObject = typeof historyEventValue === 'object'
            && !ignoreObjectTypes.includes(historyData?.type as DataType);

          if (lastValueIsObject) {
            historyEventValue = removeArrayBufferProps(historyEventValue);
          }

          if (hideTimerRef.current) {
            clearTimeout(hideTimerRef.current);
          }
          setPortInfo({
            isOutput: true,
            portType: portData.type,
            lastDetectedEventType: historyData?.type,
            lastDetectedValue: historyEventValue as string | number | boolean | Record<string, unknown>,
            lastTimestamp: historyData?.timestamp,
            lastValueIsObject,
          });
        }
      }

      const nextVisibility = isSourcePort || isTargetPort;
      setPanelVisible(nextVisibility);
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    if (!canvasDraggableRef.current) {
      canvasDraggableRef.current = document.querySelector(`.${CANVAS_DRAGGABLE_CLASS}`);
    }

    return () => {
      if (hideTimerRef.current) {
        clearTimeout(hideTimerRef.current);
      }
      document.removeEventListener('mousemove', mouseMoveHandler);
    };
  }, [plumbInstance, makePanelInvisible]);

  // Position the panel to the left of the port after the list of port types is updated
  // which happens after the panel is made visible, thus changing its width
  // NOTE: This is only for input ports
  useEffect(() => {
    const panelEl = panelElRef.current;
    const portLocation = lastPortLocationRef.current;
    // Keep the reference up to date.
    // we use it to prevent the above useEffect which has event listeners from re-running
    // when the panel visibility changes
    panelVisibleRef.current = panelVisible;
    if (panelVisible && portInfo && panelEl && portLocation && !portInfo.isOutput) {
      const panelDimensions = panelEl.getBoundingClientRect();
      panelEl.style.left = `${portLocation.left - portLocation.widgetLeft - panelDimensions.width - (PanelOffset / 2)}px`;
    }
  }, [panelVisible, portInfo]);

  return (
    <Resizable
      height={panelSize.height}
      width={panelSize.width}
      minConstraints={ showDetailsPanel
        ? [minExpandedPanelSize.width, minExpandedPanelSize.height]
        : [minContractedPanelSize.width, minContractedPanelSize.height]
      }
      resizeHandles={['se']}
      onResize={handleResize}
    >
      <div
        ref={panelElRef}
        style={portInfo?.isOutput ? {
          minWidth: showDetailsPanel ? panelSize.width: minContractedPanelSize.width,
          height: showDetailsPanel ? panelSize.height: minContractedPanelSize.height,
        } : {}}
        className={classNames(styles.Panel, {
          // [styles.PanelVisible]: panelVisible && portInfo,
          // [styles.ForcePanelInvisible]: !portInfo, // prevent showing the panel when loading the component
          [styles.ForcePanelInvisible]: !portInfo || !panelVisible,
          [styles.InputType]: portInfo?.isOutput === false,
        })}
      >
        <div className={styles.Row}>
          <div className={styles.Label}>{wpT('Type', 'Type')}:</div>
          <div className={classNames(styles.Value, styles.Type)}>
            {portTypeNames?.length ? portTypeNames?.join(', ') : wpT('NotAvailable', 'N/A')}
          </div>
        </div>
        {portInfo?.isOutput && (
          <>
            <div className={styles.Row}>
              <div className={styles.Label}>{wpT('LastValue', 'Last Value')}:</div>
              <div className={classNames(styles.Value, styles.Result, {
                [styles.Clickable]: portInfo?.lastValueIsObject,
              })} onClick={togglePanelExpansion}>
                {portInfo?.lastValueIsObject ? (
                  <>
                    <span>{Array.isArray(portInfo.lastDetectedValue) ? portTypeToString(t, DataType.Array) : portTypeToString(t, DataType.JsonObj)}</span>
                    <span>
                      {expandObjectDetails ? (
                        <CaretDownFilled  />
                      ) : (
                        <CaretRightFilled />
                      )}
                    </span>
                  </>
                ): (
                  (portInfo?.lastDetectedValue !== undefined && portInfo?.lastDetectedValue !== null) ? (
                    <>
                      {portInfo?.lastDetectedEventType === DataType.ImageData && (
                        <span>{wpT('Image', 'Image')}</span>
                      )}

                      {portInfo?.lastDetectedEventType === DataType.AudioBuffer && (
                        <span>{wpT('Audio', 'Audio')}</span>
                      )}

                      {(
                        portInfo?.lastDetectedEventType === DataType.String
                        || portInfo?.lastDetectedEventType === DataType.Number
                        || portInfo?.lastDetectedEventType === DataType.Boolean
                      ) && (
                        <span>{portInfo?.lastDetectedValue?.toString()}</span>
                      )}
                    </>
                  ) : ('N/A')
                )}
              </div>
            </div>

            <div className={classNames(styles.ExpandedDetails,
              {
                [styles.DetailsVisible]: expandObjectDetails,
                [styles.Hidden]: !portInfo?.lastValueIsObject,
              }
            )}>
              {
                (
                  portInfo?.lastDetectedEventType === DataType.JsonObj
                  || portInfo?.lastDetectedEventType === DataType.Array
                  || portInfo?.lastDetectedEventType === DataType.Rect
                  || portInfo?.lastDetectedEventType === DataType.Point
                ) && (
                  <Inspector
                    data={portInfo?.lastDetectedValue}
                    table={false}
                    // First level always expanded
                    expandLevel={1}
                    // @ts-expect-error bad typings
                    theme={customTheme}
                  />
                )
              }
            </div>
          </>
        )}

      </div>
    </Resizable>
  );
};

export default WidgetPortInfoPanel;
