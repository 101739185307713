import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Alert } from 'antd';
import { FormattedMessage } from 'react-intl';
import { RecipeLimitedPublicInfo } from '@kemu-io/kemu-types';
import FormGroup from '../../../components/form-control/formGroup/formGroup';
import StyledButton from '../../../components/form-control/styledButton/styledButton';
import { AsyncRequestStatus, AsyncState } from '../../../types/core_t';
import { getPublicRecipeInfo, importPublicRecipe } from '../../../api/recipe/recipeApi';
import AuthCard from '../../auth/AuthCard/AuthCard';
import useTranslation from '../../../common/hooks/useTranslation';
import routes from '../../../common/routes/index';
import styles from './importRecipe.module.css';
import DirectRouteWrapper from '@components/DirectRouteWrapper/DirectRouteWrapper';

/**
 * Public Recipe Import Page
 */
const ImportRecipe = (): React.JSX.Element => {
	const navigate = useNavigate();
	const [loadingDetails, setLoadingDetails] = useState<AsyncState>({ status: AsyncRequestStatus.idle });
	const [importing, setImporting] = useState<AsyncState>({ status: AsyncRequestStatus.idle });
	const [recipeInfo, setRecipeInfo] = useState<RecipeLimitedPublicInfo | null>(null);
	const { id: publicRecipeId } = useParams<{id: string}>();
	const [sessionSet, setSessionSet] = useState(false);
	const isImporting = importing.status === AsyncRequestStatus.loading;
	const [conditionsCheckbox, setConditionsCheckbox] = useState(false);
	const t = useTranslation('RecipeImportPage');

	const loadDetails = useCallback(async () => {
		try {
			if (!publicRecipeId) { return; }
			setLoadingDetails({ status: AsyncRequestStatus.loading });
			const details = await getPublicRecipeInfo(publicRecipeId);
			setRecipeInfo(details);
			setLoadingDetails({ status: AsyncRequestStatus.completed });
		} catch (e) {
			console.log('Error loading details: ', e);
			if (e?.response?.status === 404) {
				setLoadingDetails({
					status: AsyncRequestStatus.error,
					error: {
						message: t('404', 'The requested recipe does not exist or is no longer available.')
					}
				});
			} else {
				setLoadingDetails({ status: AsyncRequestStatus.error, error: e });
			}
		}
	}, [publicRecipeId, t]);

	// TODO: Add an option to change the name of the recipe before importing it (already supported by the API)
	const handleImport = async () => {
		if (!recipeInfo) { return; }
		if (!publicRecipeId) { return; }
		try {
			setImporting({ status: AsyncRequestStatus.loading });
			const details = await importPublicRecipe(publicRecipeId, `${recipeInfo.name} (imported)`);
			// FIXME: Restore direct navigation to the marketplace
			// navigate(routes.marketplace.getMyRecipesRoute(details.recipeId));
			navigate(routes.getBaseRoute());
			setImporting({ status: AsyncRequestStatus.completed });
		} catch (e) {
			console.log('Error importing: ', e);
			setImporting({ status: AsyncRequestStatus.error, error: e });
		}
	};

	const navigateHome = () => {
		navigate(routes.getBaseRoute());
	};

	useEffect(() => {
		if (!recipeInfo && loadingDetails.status === AsyncRequestStatus.idle && sessionSet) {
			loadDetails();
		}
	}, [loadingDetails, recipeInfo, loadDetails, sessionSet]);

	return (
		<DirectRouteWrapper onSessionSet={setSessionSet}>
			<AuthCard title="Import recipe">
				{loadingDetails.status === AsyncRequestStatus.error ? (
						<>
							<Alert closable={false} message={ loadingDetails.error!.message || 'Unknown Error' } type="error" />
							<div className={styles.GoHomeButton}>
								<StyledButton title={t('GoHomeBtn', 'Kemu app')} onClick={navigateHome} />
							</div>
						</>
				): <></>}

				{importing.status === AsyncRequestStatus.error ? (
					<Alert closable={true} message={importing.error!.message || 'Unknown Error'} type="error" />
				): <></>}


				{loadingDetails.status === AsyncRequestStatus.loading ? (
					<div className={styles.LoadingContainer}>
						<Spin spinning={loadingDetails.status === AsyncRequestStatus.loading} indicator={<LoadingOutlined />} />
						<p>{t('LoadingMessage')}</p>
					</div>
				) : (
					<div>
						{loadingDetails.status === AsyncRequestStatus.completed && (
							<>
								<p>
									<FormattedMessage
										id="RecipeImportPage.Question"
										defaultMessage="Do you want to add this recipe ({name}) to your library?"
										values={{
											name: recipeInfo?.name || 'Unknown'
										}}
									/></p>
								<Alert closable={false} type="warning" message={
									<span>
										<strong><FormattedMessage id="RecipeImportPage.Warning" defaultMessage="WARNING" />: </strong>
										<FormattedMessage
											id="RecipeImportPage.WarningText"
											defaultMessage="when executed, this recipe may have access to your camera and microphone. Only import recipes from sources that you trust."
										/>
									</span>
								}/>

								<FormGroup className={styles.AgreeContainer}>
									<input type="checkbox" name="agree" id="agree" onChange={(e) => setConditionsCheckbox(e.target.checked)} />
									<label className={styles.AgreeLabel} htmlFor="agree">
										<FormattedMessage
											id="RecipeImportPage.TermsText"
											defaultMessage="I accept the {risks} of importing recipes from unknown sources"
											values={{
												risks: <a href={routes.recipe.getImportRecipeRisks()}><FormattedMessage id="RecipeImportPage.RisksWord" defaultMessage="risks" /></a>,
											}}
										/>
									</label>
								</FormGroup>

								<div className={styles.ButtonContainer}>
									<StyledButton
										disabled={isImporting || !conditionsCheckbox}
										loading={isImporting} onClick={handleImport}
										title={
											<FormattedMessage
												id="RecipeImportPage.ImportBtn"
												defaultMessage="Import Recipe"
											/>
										} />
								</div>
							</>
						)}
					</div>
				)}

			</AuthCard>
		</DirectRouteWrapper>

	);
};

export default ImportRecipe;
