import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { InputRef } from 'antd';
import { useDebouncedCallback } from 'use-debounce';
import useTranslation from '@common/hooks/useTranslation';
import AlgoliaSearchInput from '@components/AlgoliaSearchInput/AlogliaSearchInput';
import { SearchInputContext } from '@src/contexts/SearchInputContext';


type Props = {
  onFocus?: () => void;
  onBlur?: () => void;
  placeholder?: string;
}

const AlgoliaSearchBox = (props: Props): React.JSX.Element => {
  const t = useTranslation('Marketplace');
  const { onSearch, onRefresh } = useContext(SearchInputContext);
  const [initialized, setInitialized] = useState(false);
  // const searchTmr = useRef<number | null>(null);
  const inputRef = useRef<InputRef>(null);
  // const currentContext = getSearchContext();

  const searchNow = useDebouncedCallback(() => {
    const query = inputRef.current?.input?.value;
    if (query !== undefined) {
      onSearch?.(query);
    }
  }, 250);

  // Wait for a bit before searching
  // const programSearch = useCallback(() => {
  //   if (searchTmr.current) {
  //     clearTimeout(searchTmr.current);
  //   }

  //   searchTmr.current = setTimeout(searchNow, 250);
  // }, [searchNow]);

  // Pressing ENTER forces a new search
  const handleKeyPressed = useCallback((evt: React.KeyboardEvent<HTMLInputElement>) => {
		if (evt.key === 'Enter') {
			evt.preventDefault();
			onRefresh?.();
		}
	}, [onRefresh]);


  // const suffix = `${results?.stats.totalHits} ${results?.stats.totalHits === 1 ? t('Search.ResultSingular', 'result') : t('Search.ResultPlural', 'results')}`;
  const suffix = '???';

  // Cancel timer on unmount
  // useEffect(() => {
  //   const lastTmr = searchTmr.current;

  //   // Don't show default results unless a context has been set
  //   if (currentContext && initialized) {
  //     searchNow(true);
  //   } else if (!initialized) {
  //     setInitialized(true);
  //   }

  //   return () => {
  //     if (lastTmr) {
  //       clearTimeout(lastTmr);
  //     }
  //   };
  // }, [searchNow, currentContext, initialized]);

  // Initial search
  useEffect(() => {
    if (onSearch) {
      onSearch('');
    }
  }, [onSearch]);

  return (
		<AlgoliaSearchInput
      onChange={searchNow}
      onKeyDown={handleKeyPressed}
      ref={inputRef}
			placeholder={props.placeholder}
      // suffix={
      //   results?.stats ? suffix : undefined
      // }
		/>
  );
};

export default AlgoliaSearchBox;
