import getFreshChatWidget from './freshChatWidget';

type WindowWithFreshDeskWidget = {
	fwSettings?: Record<string, unknown>;
	FreshworksWidget?: (action: string, option?: string, settings?: Record<string, unknown>) => void;
	FreshDeskEl?: HTMLElement | null;
};

// Taken from the Freshdesk dashboard/widgets
const WIDGET_ID = '51000001359';

export type FreshDeskInstance = {
	initialized: boolean;
	hide: () => void;
	hideLauncher: () => void;
	openArticle: (articleId: string) => void;
};


const w = window as WindowWithFreshDeskWidget;
const reportNotInitialized = () => console.error('Widget not initialized');

const freshDeskInstance: FreshDeskInstance = {
	hide: () => reportNotInitialized(),
	openArticle: () => reportNotInitialized(),
	hideLauncher: () => ({}),
	initialized: false
};


const hideWidget = () => {
	w.FreshworksWidget?.('hide', 'launcher');
};


// checks if the FreshworksWidget has been created
let checkCounter = 0;
const waitForInstanceCreated = () => {
	if (w.FreshworksWidget) {
		initWidget();
	} else {
		// Only retry for 30 seconds
		if (checkCounter++ > 300) { return; }
		setTimeout(waitForInstanceCreated, 100);
	}
};


const initWidget = () => {
	if (w.FreshworksWidget) {
		// make sure the launcher is hidden by default
		hideWidget();

		if (!freshDeskInstance.initialized) {
			freshDeskInstance.initialized = true;
			freshDeskInstance.hide = () => w.FreshworksWidget?.('close');

			freshDeskInstance.openArticle = (id: string) => {
				const chatWidget = getFreshChatWidget();
				if (chatWidget.initialized) { chatWidget.close(); }
				w.FreshworksWidget?.('open', 'article', { id });
			};
		}
	}
};

/**
 * Initializes an instance of the FreshDesk widget.
 * NOTE: not using hooks to allow multiple components to share the same instance.
 * @returns a partial object that will eventually contain the correct methods to 
 * update the user properties. You should check for the `initialized` property to know
 * if the functions can be accessed or not.
 */
const initFreshDeskWidget = (locale: string): FreshDeskInstance => {
	w.FreshDeskEl = document.getElementById('freshDesk-js-sdk');

	if (!w.FreshDeskEl) {
		const script = document.createElement('script');

		script.src = `https://aus-widget.freshworks.com/widgets/${WIDGET_ID}.js`;
		script.async = true;
		script.id = 'freshDesk-js-sdk';

		w.fwSettings = {
			widget_id: WIDGET_ID,
			locale
		};

		script.onload = () => {
			// After the script is loaded, it takes a few second for the FreshworksWidget instance to be added
			// to the global scope.
			waitForInstanceCreated();
		};


		w.FreshDeskEl = script;
		document.body.appendChild(script);
		hideWidget();
	} else {
		initWidget();
	}

	// Allow this to be accessed globally
	// @ts-expect-error temporal solution to allow hotspot widgets to access the articles
	window._kemu_help_ = {
		openArticle: freshDeskInstance.openArticle
	};

	return freshDeskInstance;
};

const getFreshDeskWidget = (): FreshDeskInstance => {
	return freshDeskInstance;
};

export default getFreshDeskWidget;
export {
	initFreshDeskWidget,
};
