import React, { useRef, useCallback, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import styles from './SearchBar.module.css';

interface Props {
	onFilter?: (text: string) => void;
}

const SearchBar = (props: Props): React.JSX.Element => {
	const intl = useIntl();
	const { onFilter } = props;
	const placeholder = intl.formatMessage({ id: 'LogicMapper.SearchBar.Placeholder', defaultMessage: 'Find Widget' });
	const searchRef = useRef<HTMLInputElement | null>(null);
	const timerRef = useRef<NodeJS.Timeout | null>(null);

	const handleFormSubmit = (event: React.SyntheticEvent) => {
		event.preventDefault();
		if (searchRef.current) {
			props.onFilter?.(searchRef.current.value);
		}
	};

	const clearFilter = useCallback(() => {
		onFilter?.('');
	}, [onFilter]);

	const clearSearchBar = () => {
		// if(searchRef.current) { 
		// 	searchRef.current.value = ''; 
		// }
		// // We can't click directly because it prevents click actions over the widgets
		// timerRef.current = setTimeout(clearFilter, 1700);
	};

	const handleInputChange = (event: React.SyntheticEvent) => {
		handleFormSubmit(event);
	};

	const handleGlobalKeys = useCallback((ev: KeyboardEvent) => {
		const special = ev.ctrlKey || ev.metaKey;
		if (ev.key.toUpperCase() === 'F' && special) {
			if (searchRef.current ) {
				searchRef.current.focus();
				// Clear any existing results
				searchRef.current.value = '';
				clearFilter();
			}
			ev.preventDefault();
		}
	}, [clearFilter]);

	useEffect(() => {
		document.addEventListener('keydown', handleGlobalKeys);
		const lastTmr = timerRef.current;
		return () => {
			if (lastTmr) { clearTimeout(lastTmr); }
			document.removeEventListener('keydown', handleGlobalKeys);
		};
	}, [handleGlobalKeys]);

	return (
		<div>
			<div className={`search-bar ${styles.container}`} data-kemu-meta="widgets-search-bar">
				<form onSubmit={handleFormSubmit}>
					<div className={`input-group ${styles.inputGroup}`}>
						<input onBlur={clearSearchBar} onChange={handleInputChange} ref={searchRef} type="text" className={`form-control ${styles.formControl}`} placeholder={placeholder}/>
						<div className="input-group-btn">
							<button data-kemu-meta="find-widget-btn" className={`btn btn-primary ${styles.btn}`}>
								<SearchOutlined />
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default SearchBar;

