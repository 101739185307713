import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import { Alert, Button, message, Space } from 'antd';
import { BroadcastEvent, EnvironmentInfo, KemuHubFunctions, SerializableServiceInfo } from '@kemu-io/hs-types';
import byteSize from 'byte-size';
import { useDebouncedCallback } from 'use-debounce';
import { WidgetLaunchpadContext } from '../../LaunchpadContext';
import { ReactComponent as InstallIcon } from '../install-icon.svg';
import styles from './ServiceInstallerModal.module.css';
import connectionManager from '@src/app/kemuHub/connectionManager';
import RoundedModal from '@components/roundedModal/roundedModal';
import useTranslation from '@hooks/useTranslation';
import useCommonWords from '@hooks/useCommonWords';
import FormGroup from '@components/form-control/formGroup/formGroup';
import StyledLabel from '@components/form-control/styledLabel/styledLabel';
import KemuSwitch from '@components/form-control/kemuSwitch/KemuSwitch';
import useHandleServiceBroadcast from '@hooks/useHandleServiceBroadcast';
import FileDropZone from '@src/features/LogicMapper/WidgetBundleModal/FileDropZone/FileDropZone';
import StyledTextArea from '@components/form-control/styledTextArea/styledTextArea';
import useActiveHubServices from '@hooks/useHubServices';

type Props = {
  visible: boolean;
  onClose: () => void;
}

type InstallationConfig = {
  /**
   * The Hub can run multiple versions of the same service concurrently.
   * However, when installing a new version, the Hub will remove the previous one. 
   * Set this to true, to allow keeping any existing versions of the service.
   */
  keepPreviousVersion?: boolean;
  /**
   * When trying to install the same version of a service, the installer will
   * throw an error. Set this flag to true to allow overwriting the current
   * version.
   */
  replaceCurrentVersion?: boolean;
  zip: ArrayBuffer | null;
}

const ServiceInstallerName = 'kemu.io.serviceInstaller';
const InstallServiceEvent = 'installService';

const getDefaultConfig = (): InstallationConfig => ({
  keepPreviousVersion: false,
  replaceCurrentVersion: false,
  zip: null,
});

const getHumanSize = (bytes: number) => {
  const size = byteSize(bytes || 0);
  return `${size.value} ${size.unit}`;
};


/**
 * Helper function to find the project creator service in the list of active services.
 * @param services a list of active services
 * @returns a service info object if the project creator service is found, otherwise null.
 */
export const findServiceInstaller = (services: SerializableServiceInfo[]): SerializableServiceInfo | null => {
  const projectCreator  = services.find((service) => service.name === ServiceInstallerName && service.internal);
  return projectCreator || null;
};


const ServiceInstallerModal = (props: Props) => {
  const { visible, onClose } = props;
  const t = useTranslation('Modal.HubService.InstallService');
  const cw = useCommonWords();
  const hubConnector = connectionManager.getConnector();
  const hubOnline = connectionManager.isReady();
  const [zipData, setZipData] = useState<Uint8Array | null>(null);
  const [config, setConfig] = useState<InstallationConfig>(getDefaultConfig());
  const [logs, setLogs] = useState<string>('');
  const [installationInProgress, setInstallationInProgress] = useState<boolean>(false);
  const [missingSecrets, setMissingSecrets] = useState<boolean>(false);
  const { setPreventClose } = useContext(WidgetLaunchpadContext);
  const [processSuccessful, setProcessSuccessful] = useState<boolean>(false);
  const logsInputRef = useRef<TextAreaRef>(null);
  const autoScrollSetAt = useRef<number>(0);
  const lastScrollAt = useRef<number>(0);
  const { services } = useActiveHubServices();
  const serviceInstaller  = findServiceInstaller(services.available);
  const [installedService, setInstalledService] = useState<SerializableServiceInfo | null>(null);

  // const scrollToBottom = useCallback(() => {
  const scrollToBottom = useDebouncedCallback(() => {
    if (logsInputRef.current?.resizableTextArea?.textArea) {
      const textAreaComponent = logsInputRef.current.resizableTextArea.textArea;
      // Used to detect when the user has scrolled manually
      autoScrollSetAt.current = Date.now();
      textAreaComponent.scrollTop = textAreaComponent.scrollHeight;
    }
  }, 100);


  useHandleServiceBroadcast<BroadcastEvent>(serviceInstaller?.name, serviceInstaller?.version, (event) => {
    if (event.source.serviceName === ServiceInstallerName) {
      const stdout = event.outputs.find(output => output.name === 'stdout');
      if (stdout) {
        let firstLogLine = false;
        setLogs((l) => {
          firstLogLine = l.split('\n').length <= 2;
          return `${l}${stdout.value}\n`;
        });

        const elapsed = Date.now() - lastScrollAt.current;

        // If the user hasn't scrolled in the last 15 seconds, scroll to the bottom
        if (elapsed > 15000 || firstLogLine) {
          scrollToBottom();
        }
      }
    }
  }, 'app', !visible);

  const handleClose = useCallback(() => {
    onClose();
    setConfig(getDefaultConfig());
    setInstallationInProgress(false);
    setProcessSuccessful(false);
    setZipData(null);
    setLogs('');
    setMissingSecrets(false);
  },[onClose]);

  const handleKeepPrevVerToggle = useCallback((checked: boolean) => {
    setConfig((prev) => ({
      ...prev,
      keepPreviousVersion: checked,
    }));
  }, []);

  const handleOpenKemuHubSettings = async () => {
    if (!installedService) { return; }

    hubConnector.executeHubFunction(KemuHubFunctions.ShowSecretsConfigScreen, [{
      name: installedService?.name,
      version: installedService?.version,
    }]).catch(res => {
      message.error(
        res.errCode === 'GUI_NOT_SUPPORTED' ? t.withBaseKey('Error')('GuiNotSupported') : res.error || t('DefaultError')
      );
    });
  };

  const handleReplaceCurrentVerToggle = useCallback((checked: boolean) => {
    setConfig((prev) => ({
      ...prev,
      replaceCurrentVersion: checked,
    }));
  }, []);

  const handleZipAdded = useCallback((data: Uint8Array) => {
    setZipData(data);
    setLogs('');
  }, []);

  const handleLogsScroll = useCallback(() => {
    const isAutoScroll = Date.now() - autoScrollSetAt.current < 200;
    if (!isAutoScroll) {
      lastScrollAt.current = Date.now();
    }
  }, []);

  const handleInstall = useCallback(async () => {
    setInstallationInProgress(true);
    setLogs('Uploading service...\n');

    try {
      if (!serviceInstaller) { return; }
      const request: InstallationConfig = {
        ...config,
        zip: (zipData?.buffer as ArrayBuffer) || null,
      };

      const response = await hubConnector.callProcessorHandler(
        serviceInstaller.sessionId,
        {} as EnvironmentInfo,
        InstallServiceEvent,
        request,
        {
          // Wait for up to 15 minutes for the project creation to conclude
          timeout: 900000,
        }
      );

      console.log('Service install response:', response);
      if (response.success) {
        setProcessSuccessful(true);
        setInstalledService(response.success as SerializableServiceInfo);
      }

      if (response.launchedWithErrors) {
        console.log('Service install error:', response.launchedWithErrors);

        if (response.launchedWithErrors.errorCode === 'MISSING_REQUIRED_SECRETS') {
          setMissingSecrets(true);
        }

        await connectionManager.refreshServicesCache();
      }
    } catch (e) {
      console.error('Service install error:', e);
      setLogs((l) => `${l}${e.message || e as string}\n`);
    }

    setInstallationInProgress(false);
    scrollToBottom();
  }, [hubConnector, config, scrollToBottom, serviceInstaller, zipData?.buffer]);

  useEffect(() => {
    if (visible) {
      setPreventClose(true);
    }

    return () => {
      setPreventClose(false);
    };
  }, [setPreventClose, visible]);

  return (
    <RoundedModal
      title={t('Title', 'Install Service')}
      visible={visible}
      onCancel={handleClose}
      closeOnMaskClick={false}
      closable={true}
      disableOkButton={
        processSuccessful
        || !hubOnline
        || installationInProgress
        || !zipData
      }
      disableCancelButton={installationInProgress}
      loadingOkBtn={installationInProgress}
      okBtnLabel={cw('Install')}
      cancelBtnLabel={processSuccessful ? cw('Close') : cw('Cancel')}
      onOk={handleInstall}
    >

      <FormGroup marginBottomLevel={3}>
        <Space.Compact style={{ width: '100%' }}>
          <FileDropZone
            accept='.zip'
            className={styles.DropZone}
            hoverClass={styles.DropHover}
            noFileElement={
              <div className={styles.CenteredContent}>{t('Description')}</div>
            }
            fileLoaded={!!zipData}
            onFileAdded={handleZipAdded}
            yesFileElement={
              <div className={styles.CenteredContent}>
                <InstallIcon className={styles.InstallIcon} />
                {zipData && getHumanSize(zipData.byteLength)}
              </div>
            }
          />
        </Space.Compact>
      </FormGroup>

      <FormGroup vCenter marginBottomLevel={2} row className={styles.SwitchGroup}>
        <StyledLabel text={t('KeepPreviousVersion')} helpText={t('KeepPreviousVersion.Help')}/>
        <KemuSwitch
          disabled={!serviceInstaller || installationInProgress}
          checked={!!config.keepPreviousVersion}
          onChange={handleKeepPrevVerToggle}
          size='small'
        />
      </FormGroup>

      <FormGroup vCenter marginBottomLevel={2} row className={styles.SwitchGroup}>
        <StyledLabel
          text={t('ReplaceCurrentVersion')}
          helpText={t('ReplaceCurrentVersion.Help')}
        />
        <KemuSwitch
          disabled={!serviceInstaller || installationInProgress}
          checked={!!config.replaceCurrentVersion}
          onChange={handleReplaceCurrentVerToggle}
          size='small'
        />
      </FormGroup>

      {!!logs.length && (
        <div className={styles.LogsContainer}>
          {!hubOnline ? (
            <div>
              <Alert
                message={t('HubOffline', 'Kemu Hub is currently offline')}
                type="error"
                showIcon
              />
            </div>
          ) : (
            <>
              <StyledTextArea
                ref={logsInputRef}
                onScroll={handleLogsScroll}
                className={styles.Logs}
                readOnly
                value={logs}
                label={t('Logs.Label', 'Logs')}
                rows={6}
                preventResize
              />

              {missingSecrets && (
                <Alert message={t('MissingSecrets', undefined, {
                  link: <Button
                    type='link'
                    onClick={handleOpenKemuHubSettings}
                    className={styles.ButtonLink}
                  >
                    {t.withBaseKey('CommonWords')('KemuHubSettings')}
                  </Button>
                })} type="warning" showIcon style={{ marginTop: 10 }} />
              )}
            </>
          )}
        </div>
      )}
    </RoundedModal>
  );
};

export default ServiceInstallerModal;
