import React, { useEffect, useState, HTMLAttributes, createRef, createElement, memo, forwardRef } from 'react';
import styles from './styledHeading.module.css';

interface Props extends HTMLAttributes<HTMLHeadingElement> {
	tag: 'h1' | 'h2' | 'h3' | 'h4';
	/** indicates if the element should start in editing mode */
	edit?: boolean;
	text: string;
	onEditStart?: ()=> void;
	onEditFinished?: (text: string)=> void;
}

const EditableHeading = (props: Props, ref: React.Ref<HTMLHeadingElement>): React.JSX.Element => {
	const { tag, edit, onEditFinished, onEditStart, text, ...rest } = props;
	const [editing, setEditing] = useState(edit || false);
	const elementRef = createRef<HTMLHeadingElement>();

	const handleChange = (evt: React.FormEvent<HTMLHeadingElement>) => {
		const finalText = (evt.currentTarget.textContent || '').trim();
		// Restore text if empty
		if (finalText === '' && elementRef.current) {
			elementRef.current.innerText = text;
		} else {
			onEditFinished?.(finalText);
		}

		setEditing(false);
	};


	const onPress = (evt: React.KeyboardEvent<HTMLHeadingElement>) => {
		if (evt.key === 'Enter') {
			evt.preventDefault();
			handleChange(evt);
		}
	};

	const handleClick = () => {
		if (editing) { return; }
		if (typeof ref === 'function') {
			ref(elementRef.current);
		}

		onEditStart?.();
		setEditing(true);
	};

	useEffect(() => {
		if (editing) {
			const range = document.createRange();
			const sel = window.getSelection();
			if (elementRef.current && sel) {
				const content = elementRef.current.textContent || '';
				const position = (content.length || 0);
				range.setStart(elementRef.current.childNodes[0], position);
				range.collapse(true);
				sel.removeAllRanges();
				sel.addRange(range);
			}

			elementRef.current?.focus();
		}
	}, [editing, elementRef, text]);

	const commonAttributes = {
		contentEditable: editing,
		suppressContentEditableWarning: true,
		onBlur: handleChange,
		onKeyPress: onPress,
		onClick: handleClick,
		className: `${styles.Header} ${props.className ? props.className : ''} ${editing ? styles.EditingHeader : ''}`,
		// Disables grammarly extension on this input (if installed)
		'data-gramm_editor': false,
		ref: elementRef,
		...rest
	};

	return (
		<div className={styles.HeaderWrapper}>
			<span>
				{ createElement(tag, commonAttributes, text) }
			</span>
		</div>
	);
};

export default memo(forwardRef<HTMLHeadingElement, Props>(EditableHeading));
