import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { IconPack, library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import widgetApiInterface from '@kemu-io/kemu-core/common/widgetApi';
import canvasManager from '@kemu-io/kemu-core/common/managers/canvasManager.js';
import kemuCore from '@kemu-io/kemu-core';
import eventHistory from '@kemu-io/kemu-core/common/eventHistory';
import * as recipeCache from '@kemu-io/kemu-core/common/recipeCache';
import { ConfigProvider } from 'antd';
import { CanvasInstance } from '@kemu-io/kemu-core/types';
import styles from './App.module.css';
import I18nProvider from './assets/i18n/I18nProvider';
import * as serviceWorker from './serviceWorker';
import { setNewVersionAvailable } from './features/interface/interfaceSlice';
import Hotspots from './@asurantech/hotspots/Hotspots';
import * as widgetApi from './api/widget/widgetApi';
import khConnectionManager from './app/kemuHub/connectionManager';
import PageLayout from './features/PageLayout/PageLayout';
import ForgotPassword from './features/auth/forgotPassword/forgotPassword';
import Login from './features/auth/login/login';
import SignUp from './features/auth/signup/signup';
// import AccountDetails from './features/auth/AccountDetails/AccountDetails';
import ErrorPage from './features/ErrorPage/ErrorPage';
import ImportRecipe from './features/directAction/importRecipe/importRecipe';
import ImportWidgetScreen from './features/directAction/importWidget/importWidget';
import AuthStateRouter from './features/AuthStateRouter/AuthStateRouter';
import UserSessionContextProvider from '@common/context/UserSessionContext/UserSessionContext';
import globals from '@common/globals';
import routes from '@common/routes/index';
import loadSpeedyVision from '@common/speedy-vision/speedyVision';
import configureAmplify from '@common/amplify';
import Logger from '@common/logger';
import useClearBundleCacheOnRefresh from '@hooks/useClearBundleCacheOnRefresh';
import usePreventNavigation from '@hooks/usePreventNavigation';

const logger = Logger('App');

configureAmplify();

if (globals.STAGE === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).__kd__ = {
    core: kemuCore,
    recipeCache,
    eventHistory,
    getThings: (index?: number) => {
      const allRecipes = recipeCache.getAllRecipes();
      const keys = Object.keys(allRecipes);
      const firstRecipe = allRecipes[keys[0]];
      const things = firstRecipe.blocks;
      const thingsKeys = Object.keys(things);
      const thing = index !== undefined ? things[thingsKeys[index]] : undefined;

      // console.log(thing ?? things);

      if (thing) {
        return { ...thing };
      } else {
        return { ...things };
      }
    }
  };
}

canvasManager.setManager({
  createCanvas: (width: number, height: number) => {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    return canvas as unknown as CanvasInstance;
  },
});


const App: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const [language] = useState('en');
  const abortLoading = globals.STAGE !== 'production';
  const initializedRef = useRef(false);
  // useUserbackWidget(abortLoading);
  useClearBundleCacheOnRefresh();
  usePreventNavigation();
  loadSpeedyVision();

  useEffect(() => {
    // The store provider seems to be causing App.tsx to be rendered twice.
    if (initializedRef.current) { return; }

    initializedRef.current = true;
    // This exports the whole font-awesome icon packs for Brand and Solid.
    library.add(fab as IconPack, fas as IconPack);
    // khConnectionManager.start();
    kemuCore.setHubConnector(khConnectionManager.getConnector());

    // Set the actual (authenticated) api so that kemu-core can fetch block information.
    // thingApiInterface.setApi(thingApi);
    widgetApiInterface.setApi(widgetApi);
    // WebServiceManager.setServiceManager(WebServiceType.Http, buildHttpService());
    // WebServiceManager.setServiceManager(WebServiceType.DynamoDb, buildDynamoDbService());
    // WebServiceManager.setServiceManager(WebServiceType.GoogleSheets, buildGoogleSheetsService());

    logger.log('Registering service worker');
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    // serviceWorker.unregister();
    serviceWorker.register({
      onUpdate: (registration) => {
        logger.log('Skipping waiting to use new version: ');
        registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
      },

      onInstalling: (state) => {
        // STATES:
        // 'installed'
        // => version skipped after the above message
        // 'activating'
        // 'activated' <== Show popup to user?
        logger.log('Service worker state: ', state);
        if (state === 'activated') {
          dispatch(setNewVersionAvailable());
        }
      }
    });
  }, [dispatch, language]);

  return (
    <div className={styles.Page}>
      <ConfigProvider
        prefixCls='kemu'
        theme={{
          components: {
            Input: {
              colorBgContainer: '#fefeff',
              activeBorderColor: '#95a0f4',
              colorText: '#495057',
              boxShadow: 'none',
              hoverBorderColor: '#d9d9d9',
              controlOutline: 'none',
            }
          },
          // set to false to remove the 'css-dev-only' added in dev mode.
          // More info here: https://github.com/ant-design/ant-design/discussions/38753
          hashed: false,
          token: {
            colorPrimary: '#4E3EFF',
            fontFamily: 'Nunito',
            colorTextBase: '#18212D'
          }
        }}
      >
        <Router>
          <I18nProvider language={ language }>
            <Hotspots />
            <UserSessionContextProvider>
              <Routes>
                <Route path={routes.getBaseRoute()} element={<PageLayout />} errorElement={<ErrorPage />}>

                  {/* Added */}
                  <Route path={routes.auth.getForgotPasswordRoute()} element={<ForgotPassword />} />
                  <Route path={routes.auth.getSignInRoute()} element={<Login />} />
                  <Route path={routes.auth.getSignUpRoute()} element={<SignUp />} />
                  {/* <Route path={routes.auth.getAccountSelectionRoute()} element={<AccountDetails />} /> */}

                  <Route path={routes.recipe.getImportRecipeDirectRoute()} element={<ImportRecipe />} />
					        <Route path={routes.widgets.getImportWidgetDirectRoute()} element={<ImportWidgetScreen />} />

                  <Route path={routes.getBaseRoute()} element={<AuthStateRouter />} />
                </Route>
                <Route path={'*'} element={<AuthStateRouter />} />
              </Routes>
            </UserSessionContextProvider>
          </I18nProvider>
        </Router>
      </ConfigProvider>
    </div>
  );
};

export default App;
