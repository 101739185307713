import classNames from 'classnames';
import React, { FocusEvent, forwardRef, memo, useCallback, useState } from 'react';
import {
  EyeOutlined,
  EyeInvisibleOutlined,
} from '@ant-design/icons';
import styles from './TextInput.module.css';

type Props = {
  label?: string;
  className?: string;
  placeholder?: string;
  readOnly?: boolean;
	value?: string;
	name?: string;
	defaultValue?: string;
  disabled?: boolean;
  type?: 'text' | 'password';
  /** used to uniquely identify the input in event handlers */
  eventId?: string,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  /**
   * @param text the new text
   * @param key same as properties key
   */
	onBlur?: (text: string, eventId: string | undefined, originalEvent: FocusEvent<HTMLInputElement>) => void;
}

const TextInputComponent = (props: Props, ref: React.Ref<HTMLInputElement>): React.JSX.Element => {
  const { onBlur, eventId } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = useCallback(() => {
    setShowPassword(p => !p);
  }, []);

  const handleBlur = useCallback((evt: FocusEvent<HTMLInputElement>) => {
		onBlur?.(evt.currentTarget.value, eventId, evt);
	}, [onBlur, eventId]);

  return (
    <div className={classNames('widget-input', styles.Wrapper)}>
      {props.label && (
        <label>{props.label}</label>
      )}

      <input
        onBlur={handleBlur}
        type={!showPassword ? props.type || 'text' : 'text'}
        defaultValue={props.defaultValue}
        onKeyDown={props.onKeyDown}
        onChange={props.onChange}
        value={props.value}
        name={props.name}
        readOnly={props.readOnly}
        ref={ref}
        placeholder={props.placeholder}
        className={classNames(styles.Input, props.className)}
        data-testid="input"
      />

      {props.type === 'password' && (
        <span className={styles.EyeBtn} onClick={handleTogglePassword} data-testid="show-pwd-btn">
          {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
        </span>
      )}

    </div>
  );
};

const TextInput = memo(forwardRef<HTMLInputElement, Props>(TextInputComponent));
export default TextInput;
