import React, { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { posthog } from 'posthog-js';
import styles from './PageLayout.module.css';
import { UserSessionContext } from '@common/context/UserSessionContext/UserSessionContext';
// import getFreshChatWidget from '@src/app/recipe/freshChatWidget';
// import getFreshDeskWidget from '@src/app/recipe/freshDeskWidget';
// import routes from '@common/routes';

const PageLayout = () => {
  // const navigate = useNavigate();
	const session = useContext(UserSessionContext);
	// const organizationType = session.user?.attributes['custom:organization'];
	// const userId = session.user?.attributes['sub'];

	// Make sure widgets are hidden if not logged in.
	useEffect(() => {
		// const freshChat = getFreshChatWidget();
		// const freshDesk = getFreshDeskWidget();
    // if (session.state !== 'LoggedIn') {
    //   freshChat.hide();
    //   freshDesk.hideLauncher();
    // }

		// Using try as a workaround for:
		// https://github.com/PostHog/posthog-js/issues/323
		try {
			// Reset user info in case multiple users share the same computer.
      console.log('Posthog reset');
			posthog.reset(true);
		} catch (e) {
			console.warn('Failed to reset posthog');
		}

    // console.log(`User state: ${session.state}`);

		// if (session.state === 'LoggedIn') {
		// 	if (organizationType) {
		// 		// navigate(routes.getBaseRoute());
		// 	} else if (userId) {
		// 		navigate(routes.auth.getAccountSelectionWithUserRoute(userId));
		// 	}
		// // } else if (session.state === 'NotLoggedIn') {
    // //   navigate(routes.auth.getSignInRoute());
    // }
	}, [session.state]);

  // console.log('PageLayout');
  return (
    <div className={`${styles.Container} ${styles.Light}`}>
			<div className={styles.Section}>
        <Outlet />
      </div>
    </div>
  );
};

export default PageLayout;
