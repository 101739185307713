import React, { useEffect, useRef, useState } from 'react';

interface Props {
	text: string;
	maxChars: number;
	showFullOn?: 'hover' | 'click';
	onVisibilityChange?: (visible: boolean) => void;
	truncateMiddle?: boolean;
	// expandOnClick?: boolean;
	/** 
	 * number of milliseconds to wait before mouseLeave is detected. Defaults to 150ms
	 **/
	leaveTimeout?: number;
	suffix?: React.ReactNode;
}

const TruncatedText = (props: Props): React.JSX.Element => {
	const [showFull, setShowFull] = useState(false);
	let truncated = props.text?.length > props.maxChars ? `${props.text.substring(0, props.maxChars-3)}...` : props.text;
	// Truncate in the middle, keeping the total length as props.maxChars 
	if (props.truncateMiddle && props.text?.length > props.maxChars) {
		const half = props.maxChars / 2;
		truncated = `${props.text.substring(0, half-3)}...${props.text.substring(props.text.length - half, props.text.length)}`;
	}

	const isTruncated = truncated !== props.text;
	const leaveTmrRef = useRef<NodeJS.Timeout | null>(null);

	const onHover = () => {
		if (leaveTmrRef.current) {
			clearTimeout(leaveTmrRef.current);
		}
		if (props.showFullOn && isTruncated) {
			setShowFull(true);
			props.onVisibilityChange?.(true);
		}
	};

	const triggerLeave = () => {
		setShowFull(false);
		props.onVisibilityChange?.(false);
	};

	const onLeave = () => {
		if (props.showFullOn && isTruncated) {
			leaveTmrRef.current = setTimeout(triggerLeave, props.leaveTimeout || 150);
		}
	};

	useEffect(() => {
		return () => {
			if (leaveTmrRef.current) {
				clearTimeout(leaveTmrRef.current);
			}
		};
	}, []);

	return (
		<div
			onMouseEnter={props.showFullOn === 'hover' ? onHover : undefined }
			onMouseLeave={props.showFullOn === 'hover' ? onLeave : undefined }

			onClick={props.showFullOn === 'click' ? onHover : undefined }
			onBlur={props.showFullOn === 'click' ? onLeave : undefined}
			tabIndex={0}
			style={{
				width: '100%',
				height: '100%',
			}}
		>
			{showFull ? (props.text) : truncated}
			{props.suffix}
		</div>
	);
};

export default TruncatedText;
