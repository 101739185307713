import { BasicFailedServiceInfo, KemuHubFunctions } from '@kemu-io/hs-types';
import classNames from 'classnames';
import React from 'react';
import Badge from 'antd/lib/badge';
import Button from 'antd/lib/button';
import { message } from 'antd';
import BaseServiceIcon from '../../BaseServiceIcon/BaseServiceIcon';
import SelectedServiceFooter from '../SelectedServiceFooter/SelectedServiceFooter';
import styles from './FailedService.module.css';
import TruncatedText from '@components/truncatedText/truncatedText';
import { useTranslation } from '@hooks/index';
import useKemuHubLink from '@hooks/useHubLink';

type Props = {
  service: BasicFailedServiceInfo;
  isSelected: boolean;
  setCustomFooter: (footer: React.ReactElement | null) => void;
  onClick: (service: BasicFailedServiceInfo) => void;
}

const FailedServiceFooter = (service: BasicFailedServiceInfo) => {
  const hasMissingSecrets = service.errorCode === 'MISSING_REQUIRED_SECRETS';
  const t = useTranslation('WidgetLaunchpad.FailedService');
  const { connector } = useKemuHubLink();

  const handleOpenKemuHubSettings = async () => {
    console.log('open kemu hub settings');
    connector.executeHubFunction(KemuHubFunctions.ShowSecretsConfigScreen, [{
      name: service.name,
      version: service.version,
    }]).catch(res => {
      message.error(
        res.errCode === 'GUI_NOT_SUPPORTED' ? t.withBaseKey('Error')('GuiNotSupported') : res.error || t('DefaultError')
      );
    });
  };

  return (
    <SelectedServiceFooter
      name={`${service.name} (${service.version})`}
      descriptionClassName={styles.FooterDescription}
      description={
        <div>
          {hasMissingSecrets ? t('MissingSecrets', undefined, {
            link: <Button
              type='link'
              onClick={handleOpenKemuHubSettings}
              className={styles.Link}
            >
              {t.withBaseKey('CommonWords')('KemuHubSettings')}
            </Button>
          }) : t('UnknownError')}
        </div>
      }
      hideUninstallBtn={true}
    />
  );
};

const FailedService = (props: Props) => {
  const { service, isSelected, onClick, setCustomFooter } = props;
  const t = useTranslation('WidgetLaunchpad.FailedService');
  const handleServiceClick = () => {
    onClick(service);
    setCustomFooter(<FailedServiceFooter {...service} />);
  };


  return (
    <div
      className={
        classNames(styles.ServiceButton)
      }
      data-kemu-meta={`hub-failed-service-${service.name}-${service.version}`}
      onClick={handleServiceClick}
    >
      <Badge
        count={t('EnvVarBadge')}
        title={t('EnvVarBadgeTitle')}
        size='small'
        showZero
        offset={[-5, 0]}
        color='red'
      >
        <BaseServiceIcon
          svgIcon={service.svgIcon}
          color={service.color}
          selected={isSelected}
        />
      </Badge>
      <div className={classNames(styles.ServiceName, 'noselect')}>
        <label>
          <TruncatedText
            text={`${service.title || service.name}`}
            maxChars={24}
            showFullOn="hover"
          />
        </label>
      </div>
    </div>

  );
};

export default FailedService;
