import React, { createContext } from 'react';

type SearchInputContextType = {
  collection?: string;
  onSearch?: (query: string) => void | Promise<void>;
  onRefresh?: () => void | Promise<void>;
}

export const SearchInputContext = createContext<SearchInputContextType>({
  onSearch: () => void 0,
  onRefresh: () => void 0,
});

const SearchInputContextProvider = SearchInputContext.Provider;

export default SearchInputContextProvider;

export type { SearchInputContextType };
