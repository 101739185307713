import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import {
	BlockCanvasInfo,
	ChildBlock,
	ThingCustomWidget,
	BlockMap,
	TransferProgressEvent,
} from '@kemu-io/kemu-core/types';
import {
	constants,
} from '@kemu-io/kemu-core/common';
import { LimitedThingInfo, RecipeCloudInfo, RecipeType } from '@kemu-io/kemu-types';
// import { DEFAULT_THING_ID } from '@kemu-io/kemu-core/dist/common/constants';
import getInitialRecipeState from './initialRecipeState';
import * as RecipeActions from '@src/app/recipe/utils';
import { AsyncRequestStatus, RequireOnlyOne, SelectedBlockInfo, WithOptional } from '@src/types/core_t';
import { RootState } from '@src/app/store';
import { safeJSONStringify } from '@common/utils';
import { openRecipeReducer } from '@src/app/reducers/workspace/openRecipeReducer';
import terminateRecipeReducer from '@src/app/reducers/workspace/terminateRecipeReducer';
import { createCloudRecipeReducer } from '@src/app/reducers/workspace/createCloudRecipeReducer';
import { createBrowserRecipeReducer } from '@src/app/reducers/workspace/createBrowserRecipeReducer';
import { RecipeMetaFields } from '@src/types/recipe_t';

const { DEFAULT_THING_ID } = constants;

interface InstallationInfo {
	errorMsg: string;
	block: LimitedThingInfo,
	progress: TransferProgressEvent
}

export type BlockInstallationProcess = RequireOnlyOne<InstallationInfo, 'errorMsg' | 'progress'>

interface CurrentRecipeInfo {
	poolId: string | null;
	/** information about the entity once the recipe has been saved */
	entityInfo?: null | {
		dbId: string;
		dbVersion: number;
	};
	cloud: RecipeCloudInfo | null;
	type: RecipeType;
	name: string;
	isDefault?: boolean;
	needsSaving: boolean;
	blocks: {
		collection: BlockMap;
		status: AsyncRequestStatus;
		error?: SerializedError;
	};
	openedWithErrors: boolean;
}

export type FolderPathInfo = {
	groupId: string;
	name: string;
	description?: string;
}

export type CloudRecipeCreation = {
	status: AsyncRequestStatus;
	error?: SerializedError;
}

export interface WorkspaceState {
	/** Indicates a new recipe is in the process of being loaded */
	openingRecipe: boolean;
	selectedBlockInfo: SelectedBlockInfo | null,
	/** contains information a bout the block currently being installed for a given recipe */
	blockInstallationProcess: Record<string, BlockInstallationProcess>,
	baseRecipeLoaded: boolean;

	/** 
	 * Contains the list of ids of nested folders or custom widgets (after the selectedBlockId).
	 * The last item in the list determines which widgets are actually visible.
	 **/
	folderPath: FolderPathInfo[];

	currentRecipe: CurrentRecipeInfo;

	creatingCloudRecipe: CloudRecipeCreation | null;
	/** 
	 * A dictionary of custom widgets created by all the Things in the recipe grouped by
	 * the Thing type (database id)
	 **/
	customWidgetsByThing: {
		/** 
		 * Map of custom widgets per Thing. The key is the id of the thing in the database.
		 **/
		[thingDbId: string]: {
			/** 
			 * keeps track of how many instances of the same thing have been added, so that
			 * only after all instances have been removed, the templates are also removed.
			 **/
			instancesAdded: number;
			templates: Record<string, ThingCustomWidget>;
		}
	}
}


const initialState = getInitialRecipeState();


interface SetBlockCanvasInfo {
	// TODO: This could be inferred form the state?
	recipeId: string;
	/** id of hte block in the recipe */
	blockId: string;
	/** info for the block 'canvas' property */
	canvasInfo: BlockCanvasInfo;
}

interface ChildBlockInfo {
	recipeId: string;
	/** the recipeId of the parent block */
	source: string;
	/** the recipeId of the target block */
	target: string;
	sourcePortId: string;
	targetPortId: string;
}

export const workspaceSlice = createSlice({
	name: 'workspace',
	initialState,
	reducers: {

		/** Marks a recipe as 'modified' and in the need for saving */
		setRecipeNeedsSaving: (state, action: PayloadAction<boolean>) => {
			if (state.currentRecipe.poolId) {
				state.currentRecipe.needsSaving = action.payload;
			}
		},
		/* setClearRecipeAction: (state) => {
			state.currentRecipe.poolId = null;
			state.currentRecipe.blocks.collection = {};
			state.currentRecipe.needsSaving = false;
		}, */

		setRecipeMetaAction: (state, action: PayloadAction<WithOptional<RecipeMetaFields, 'authorId'> & {poolId?: string}>) => {
			const poolId = action.payload.poolId || state.currentRecipe.poolId;
			if (poolId) {
				// Update meta on kemu-core
				RecipeActions.updateRecipeCacheMeta(poolId, {
					...action.payload,
				});

				// Update meta locally to keep state in sync
				if (action.payload.name) { state.currentRecipe.name = action.payload.name; }
				state.currentRecipe.poolId = poolId;
				state.currentRecipe.needsSaving = false;
				state.currentRecipe.entityInfo = {
					...state.currentRecipe.entityInfo,
					dbId: action.payload.id,
					dbVersion: action.payload.version,
				};

			}
		},

		setRecipeName: (state, action: PayloadAction<string>) => {
			if (state.currentRecipe.poolId) {
				RecipeActions.updateRecipeCacheMeta(state.currentRecipe.poolId, { name: action.payload });
				state.currentRecipe.name = action.payload;
				state.currentRecipe.needsSaving = true;
			}
		},

		pushFolderPath: (state, action: PayloadAction<FolderPathInfo>) => {
			state.folderPath.push({
				groupId: action.payload.groupId,
				name: action.payload.name,
				description: action.payload.description
			});
		},

		/** changes the last item in the folder path */
		replaceCurrentFolderPath: (state, action: PayloadAction<FolderPathInfo>) => {
			if (state.folderPath.length) {
				state.folderPath[state.folderPath.length - 1] = action.payload;
			}
		},

		gotoFolderPathId: (state, action: PayloadAction<string>) => {
			const newPath: FolderPathInfo[] = [];
			state.folderPath.some(pathItem => {
				newPath.push(pathItem);
				// Exit when item is found
				if (pathItem.groupId === action.payload) { return true; }
			});

			state.folderPath = newPath;
		},

		gotoBlockRoot: (state) => {
			state.folderPath = [];
		},

		setRecipeType: (state, action: PayloadAction<{ type: RecipeType, cloudInfo: RecipeCloudInfo | null }>) => {
			state.currentRecipe.type = action.payload.type;
			state.currentRecipe.cloud = action.payload.cloudInfo;

			if (state.currentRecipe.poolId) {
				RecipeActions.updateRecipeCacheMeta(state.currentRecipe.poolId, {
					recipeType: action.payload.type,
				});
			}
		},

		// setRecipeDbId: (state, action: PayloadAction<string>) => {
		// 	state.currentRecipe.dbId = action.payload;
		// },

		clearInstallationProgress: (state, action: PayloadAction<'all' | {id: string, version: string}>) => {
			if (action.payload === 'all') {
				state.blockInstallationProcess = {};
			} else {
				const remaining = { ...state.blockInstallationProcess };
				delete remaining[`${action.payload.id}_${action.payload.version}`];
				state.blockInstallationProcess = remaining;
			}
		},

		// TODO: Shouldn't this part of the interfaceSlice?
		setInstallationProgress: (state, action: PayloadAction<{id: string, version: string} & BlockInstallationProcess>) => {
			state.blockInstallationProcess = {
				...state.blockInstallationProcess,
				[`${action.payload.id}_${action.payload.version}`]: action.payload
			};

			// if(typeof action.payload === 'string'){
			// 	const currentBlock = state.blockInstallationProcess.blockInfo;
			// 	state.blockInstallationError = <InstallationErrorInfo>{
			// 		cause: action.payload,
			// 		blockId: currentBlock?.id,
			// 		blockName: currentBlock?.name,
			// 		blockVersion: currentBlock?.version,
			// 	};

			// 	state.blockInstallationProcess = null;
			// }else{
			// 	state.blockInstallationProcess = action.payload;
			// 	if(action.payload === null) { state.blockInstallationError = null; }
			// }
		},

		/**
		 * Stores the id of the target block in the canvas
		 */
		openBlock: (state, action: PayloadAction<SelectedBlockInfo | null>) => {
			state.selectedBlockInfo = action.payload;
		},

		updateBlockCanvasInfo: (state, action: PayloadAction<SetBlockCanvasInfo>) => {
			const block = state.currentRecipe.blocks.collection[action.payload.blockId];
			if (block) {
				state.currentRecipe.blocks.collection[block.recipeId] = {
					...block,
					canvas: {
						...block.canvas,
						...action.payload.canvasInfo
					}
				};
			}


			RecipeActions.setBlockCanvasInfo(action.payload.recipeId, action.payload.blockId, state.currentRecipe.blocks.collection[block.recipeId].canvas);
		},

		// addBlock: (state, action: PayloadAction<{blockInfo: LimitedThingInfo, alowOnlyOneType?: boolean}>) => {
		// 	if (!state.currentRecipe.poolId) { return; }

		// 	// Add to the recipe
		// 	const addedBlock = RecipeActions.addBlock(state.currentRecipe.poolId, action.payload.blockInfo, action.payload.alowOnlyOneType);
		// 	if (addedBlock) {
		// 		// Add to global state
		// 		// IMPORTANT: Some widgets (such as the the drawPixels widget) use private properties
		// 		// to store references of large array buffers (ImageData), these arrays could have over
		// 		// 100k items depending on the resolution of the image. Here we remove such properties before
		// 		// attempting to stringify the gates, otherwise this will cause a HUGE performance drop.
		// 		const cleanCopy = safeJSONStringify(addedBlock);
		// 		state.currentRecipe.blocks.collection[addedBlock.recipeId] = JSON.parse(cleanCopy);
		// 		state.currentRecipe.needsSaving = true;
		// 	}
		// },

		// removeBlock: (state, action: PayloadAction<string>) => {
		// 	if (!state.currentRecipe.poolId) { return; }
		// 	const remainingBlocks = RecipeActions.removeBlock(state.currentRecipe.poolId, action.payload);
		// 	const cleanCopy = safeJSONStringify(remainingBlocks);
		// 	const clonedBlocks = JSON.parse(cleanCopy);
		// 	state.currentRecipe.blocks.collection = clonedBlocks;
		// 	state.currentRecipe.needsSaving = true;
		// 	if (state.selectedBlockInfo?.recipeId === action.payload) {
		// 		state.selectedBlockInfo = null;
		// 	}
		// },

		// duplicateBlock: (state, action: PayloadAction<string>) => {
		// 	if (!state.currentRecipe.poolId) { return; }
		// 	// Add to the recipe
		// 	const clonedBlock = RecipeActions.duplicateBlock(state.currentRecipe.poolId, action.payload);
		// 	// Add (a copy - of the copy, yes -) to the global state
		// 	const cleanCopy = safeJSONStringify(clonedBlock);
		// 	state.currentRecipe.needsSaving = true;
		// 	state.currentRecipe.blocks.collection[clonedBlock.recipeId] = JSON.parse(cleanCopy);
		// },

		addChild: (state, action: PayloadAction<ChildBlockInfo>) => {
			const childrenList = RecipeActions.registerChildBlock(action.payload.recipeId, action.payload.source, action.payload.target, action.payload.sourcePortId, action.payload.targetPortId);
			state.currentRecipe.blocks.collection[action.payload.source].children = JSON.parse(JSON.stringify(childrenList));
			state.currentRecipe.needsSaving = true;
			// state.currentRecipe.blocks.collection[action.payload.source].children.push(newChild);
		},

		removeChild: (state, action: PayloadAction<ChildBlockInfo>) => {
			const childrenList = RecipeActions.removeChildBlock(action.payload.recipeId, action.payload.source, action.payload.target, action.payload.sourcePortId, action.payload.targetPortId);
			state.currentRecipe.blocks.collection[action.payload.source].children = JSON.parse(JSON.stringify(childrenList));
			state.currentRecipe.needsSaving = true;
		},

		addCustomWidgetTemplates: (state, action: PayloadAction<{ thingDbId: string, templates: Record<string, ThingCustomWidget> }>) => {
			state.customWidgetsByThing = {
				...state.customWidgetsByThing,
				[action.payload.thingDbId]: {
					instancesAdded: state.customWidgetsByThing[action.payload.thingDbId]?.instancesAdded + 1 || 1,
					templates:	action.payload.templates
				}
			};
		},

		/** 
		 * Removes the templates generated by the given Thing id 
		 * @param action.payload is the id of the Thing in the database.
		 **/
		removeCustomWidgetTemplate: (state, action: PayloadAction<string>) => {
			const templates = { ...state.customWidgetsByThing };
			const totalRemaining = templates[action.payload]?.instancesAdded - 1 || 0;

			if (!totalRemaining) {
				// Remove all the things from the given thing
				delete templates[action.payload];
			} else {
				templates[action.payload] = {
					...templates[action.payload],
					instancesAdded: totalRemaining
				};
			}

			state.customWidgetsByThing = {
				...templates,
			};
		}
	},

	extraReducers: (builder) => {
		openRecipeReducer(builder);
		terminateRecipeReducer.terminateRecipeReducer(builder);
		createCloudRecipeReducer(builder);
		createBrowserRecipeReducer(builder);
	}
});



export const selectedBlock = (state: RootState): SelectedBlockInfo | null => state.workspace.selectedBlockInfo;
export const selectCurrentRecipe = (state: RootState): CurrentRecipeInfo => state.workspace.currentRecipe;
export const currentRecipeTitle = (state: RootState): string => state.workspace.currentRecipe.name;
export const currentRecipePoolId = (state: RootState): string | null => state.workspace.currentRecipe.poolId;
export const fetchRecipeState = (state: RootState): AsyncRequestStatus => state.workspace.currentRecipe.blocks.status;
export const blocksInRecipe = (state: RootState): BlockMap => state.workspace.currentRecipe.blocks.collection;
export const blockInstallProcess = (state: RootState): Record<string, BlockInstallationProcess> => state.workspace.blockInstallationProcess;
// export const blockInstallError = (state: RootState): InstallationErrorInfo | null => state.workspace.blockInstallationError;
export const unsavedChanges = (state: RootState): boolean => state.workspace.currentRecipe.needsSaving;
export const selectCurrentRecipeType = (state: RootState): RecipeType => state.workspace.currentRecipe.type;
export const selectCurrentRecipeCloudInfo = (state: RootState): RecipeCloudInfo | null => state.workspace.currentRecipe.cloud;

export const selectFolderPath = (state: RootState): FolderPathInfo[] => state.workspace.folderPath;
/**
 * @returns the information of the last folder in the path or null if no folder has been selected
 */
export const selectVisibleGroup = (state: RootState): FolderPathInfo | null => state.workspace.folderPath.length ? state.workspace.folderPath[state.workspace.folderPath.length-1] : null;

export const selectOpeningRecipe = (state: RootState): boolean => state.workspace.openingRecipe;

export const selectCreatingCloudRecipe = (state: RootState): CloudRecipeCreation | null => state.workspace.creatingCloudRecipe;

export type CustomWidgetByThing = {
	thingIcon: string;
	thingName: string;
	thingType: string;
	thingDbId: string;
	widgets: Record<string, ThingCustomWidget>;
};

export const selectCustomWidgetTemplates = (state: RootState): CustomWidgetByThing[] => {
	const templates = Object.keys(state.workspace.customWidgetsByThing).map((thingDbId) => {
		// const thingInfo = state.thing.things.find((thing) => thing.id === thingDbId);
		// if (!thingInfo) { return null; }

		const item: CustomWidgetByThing = {
			thingIcon: '',
			thingName: 'default',
			thingType: '',
			thingDbId: DEFAULT_THING_ID,
			widgets: state.workspace.customWidgetsByThing[thingDbId].templates
		};

		return item;
	});

	const filtered = templates.filter((template) => template !== null) as CustomWidgetByThing[];
	return filtered;
};

export const selectThingChildren = (state: RootState, thingId: string | null): ChildBlock[] | null => {
	if (!thingId) { return null; }

	const children = state.workspace.currentRecipe.blocks.collection[thingId]?.children;
	return children || null;
};


/**
 * Returns a reduced copy of the blocks present in a recipe
 */
// export const stripedBlocksInRecipe = (state: RootState): Record<string, ReducedRecipeBlock> =>{
// 	const filtered: {[k: string]: ReducedRecipeBlock} = {};
// 	for(const blockId in state.workspace.blocks.collection){
// 		filtered[blockId] = {
// 			recipeId: blockId,
// 			children: state.workspace.blocks.collection[blockId].children
// 		};
// 	}

// 	return filtered;
// };

// export const getBlockInfoById = (id: string) => (state: RootState): RecipeBlock  => {
// 	return state.workspace.blocks.collection[id];
// };

export const {
	openBlock: openBlockAction,
	updateBlockCanvasInfo,
	// removeBlock: removeBlockAction,
	// addBlock,
	// duplicateBlock,
	addChild,
	removeChild,
	setInstallationProgress,
	// setRecipeDbId,
	clearInstallationProgress,
	setRecipeMetaAction,
	setRecipeName,
	setRecipeType,
	pushFolderPath,
	gotoFolderPathId,
	gotoBlockRoot: gotoBlockRootAction,
	replaceCurrentFolderPath: replaceCurrentFolderPathAction,
	addCustomWidgetTemplates: addCustomWidgetTemplatesAction,
	removeCustomWidgetTemplate: removeCustomWidgetTemplateAction,
	setRecipeNeedsSaving,
	/* setClearRecipeAction, */
} = workspaceSlice.actions;

// Export reducer's actions
export const { terminateRecipeAction } = terminateRecipeReducer;

export const selectBaseRecipeLoaded = (state: RootState): boolean => state.workspace.baseRecipeLoaded;

export default workspaceSlice.reducer;
