import { useCallback } from 'react';
import { fromEvent, FileWithPath } from 'file-selector';

type FilePickerFile = {
  data: ArrayBuffer;
  name: string;
  size: number;
}

type FileSelectionResult = {
  files: FilePickerFile[];
}

type FilePickerInstance = {
  openFileSelector: () => Promise<FileSelectionResult>;
}

type FilePickerProps = {
  accept?: string;
  multiple?: boolean;
}

const openFileDialog = (
  accept: string,
  multiple: boolean,
  callback: (arg: Event) => void,
  initializeWithCustomAttributes?: (arg: HTMLInputElement) => void
) => {
  // Create an input element
  const inputElement = document.createElement('input');
  // Hide element and append to body (required to run on iOS safari)
  inputElement.style.display = 'none';
  document.body.appendChild(inputElement);
  // Set its type to file
  inputElement.type = 'file';
  // Set accept to the file types you want the user to select.
  // Include both the file extension and the mime type
  // if accept is "*" then dont set the accept attribute
  if (accept !== '*') {
    inputElement.accept = accept;
  }

  // Accept multiple files
  inputElement.multiple = multiple;
  // set onchange event to call callback when user has selected file
  //inputElement.addEventListener('change', callback);
  inputElement.addEventListener('change', arg => {
    callback(arg);
    // remove element
    document.body.removeChild(inputElement);
  });

  if (initializeWithCustomAttributes) {
    initializeWithCustomAttributes(inputElement);
  }
  // dispatch a click event to open the file dialog
  inputElement.dispatchEvent(new MouseEvent('click'));
};

const parseFile = (file: FileWithPath): Promise<{
  content: ArrayBuffer;
  mimeType: string;
  fileName: string;
}> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    const onFileLoaded = (e: ProgressEvent<FileReader>) => {
      const content = e.target?.result as ArrayBuffer | Uint8Array;
      let ab: ArrayBuffer;
      if (content instanceof ArrayBuffer) {
        ab = content;
      } else {
        const uint8Array = content as Uint8Array;
        ab = uint8Array.buffer.slice(uint8Array.byteOffset, uint8Array.byteOffset + uint8Array.byteLength) as ArrayBuffer;
      }

      resolve({
        content: ab,
        mimeType: file.type,
        fileName: file.name,
      });

    };

    reader.onload = onFileLoaded;
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
};

const useFilePicker = (props: FilePickerProps): FilePickerInstance => {
  const openFileSelector = useCallback(() => {
    return new Promise<FileSelectionResult>((resolve, reject) => {
      openFileDialog(props.accept || '*', Boolean(props.multiple), async (event) => {
        try {
          const files = await fromEvent(event) as FileWithPath[];
          const results = files.map(async (f) => {
            const fileData = await parseFile(f);
            return {
              data: fileData.content,
              name: fileData.fileName,
              size: fileData.content.byteLength,
            } as FilePickerFile;
          });

          const parsedFiles = await Promise.all(results);

          resolve({
            files: parsedFiles,
          });
        } catch (e) {
          reject(e);
        }
      });
    });
  }, [props.accept, props.multiple]);

  return {
    openFileSelector,
  };
};

export default useFilePicker;
